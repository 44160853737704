import './App.css';
import './Presentation/components/Sass/Global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AccountLogin from './Presentation/Views/Account/Login/AccountLogin';
import DashboardDashboard from './Presentation/Views/Dashboard/Dashboard/DashboardDashboard';
import PatientDetailEdit from './Presentation/Views/Patient/DetailEdit/PatientDetailEdit';
import PatientRegister from './Presentation/Views/Patient/Register/PatientRegister';
import Measurement from './Presentation/Views/Patient/Measurement/Measurement';
import Calculation from './Presentation/Views/Patient/Calculation/Calculation';
import Medical from './Presentation/Views/Patient/Medical/Medical';
import Recap from './Presentation/Views/Report/Recap/Recap';

import { PrivateRoute } from './_helpers/PrivateRoute';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/login" element={
              <PrivateRoute>
                <AccountLogin />
              </PrivateRoute>
          } />
          <Route path="/" element={
              <PrivateRoute>
                <DashboardDashboard />
              </PrivateRoute>
          } />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <DashboardDashboard />
            </PrivateRoute>
          } />
          <Route path="/patient" element={
              <PrivateRoute>
                <PatientDetailEdit />
              </PrivateRoute>
          } />
          <Route path="/patient/register" element={
              <PrivateRoute>
                <PatientRegister />
              </PrivateRoute>
          } />
          <Route path="/measurement" element={
              <PrivateRoute>
                <Measurement />
              </PrivateRoute>
          } />
          <Route path="/calculation" element={
              <PrivateRoute>
                <Calculation />
              </PrivateRoute>
          } />
          <Route path="/medical" element={
              <PrivateRoute>
                <Medical />
              </PrivateRoute>
          } />
          <Route path="/recap" element={
              <PrivateRoute>
                <Recap />
              </PrivateRoute>
          } />
        </Routes>
      </div >
    </BrowserRouter>
  );
}

export default App;
