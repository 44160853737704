import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import "./BabyForm.scss";

import DatePicker from 'react-date-picker';
import { conversionDateToDefaultFormat, conversionMonthToString } from "../../../_helpers/CalculateAge"

import LoadingButton from "../LoadingComponent/LoadingButton";

import BabyForm from "./BabyForm";

export default function BabyRegisterForm(props) {
    const saveButtonLoading = props.saveButtonLoading ?? false;
    const inputEnabled = props.inputEnabled ?? true;
    const [detailPatient, setDetailPatient] = useState(props.detailPatient ?? {});

    const handleChange = (event) => 
    {
        const name = event.target.name;
        const value = event.target.value;
        setDetailPatient(values => ({...values, [name]: value}));
    }

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const fileHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setDetailPatient(values => ({...values, 'family_card': event.target.files[0]}));
        setIsFilePicked(true);
    };
    
    const [dateBirthValue, setDateBirthValue] = useState('tanggal_lahir' in detailPatient ? new Date(detailPatient.tanggal_lahir) : new Date());
    const onDateBirthChange = (val) => {
        setDateBirthValue(val);
        // backend format date
        setDetailPatient(values => ({...values, 'tanggal_lahir': val.getFullYear() + "-" + (val.getMonth()+1) + "-" + val.getDate()}));
    }

    const handleSave = () => {
        if (!("nama_balita" in detailPatient) || detailPatient.nama_balita.trim() === "") {
            alert("Masukkan Nama Balita")
        } else if (!("nik_balita" in detailPatient) || detailPatient.nik_balita.trim() === "") {
            alert("Masukkan NIK Balita")
        } else if (!("jenis_kelamin" in detailPatient) || detailPatient.jenis_kelamin.trim() === "") {
            alert("Masukkan Jenis Kelamin Balita")
        } else if (!("tanggal_lahir" in detailPatient) || detailPatient.tanggal_lahir.trim() === "") {
            alert("Masukkan Tanggal Lahir Balita")
        } else if (!("alamat" in detailPatient) || detailPatient.alamat.trim() === "") {
            alert("Masukkan Alamat")
        } else if (!("golongan_darah" in detailPatient) || detailPatient.golongan_darah.trim() === "") {
            alert("Masukkan Golongan Darah Balita")
        } else if (!("berat_badan_lahir_bayi" in detailPatient) || detailPatient.berat_badan_lahir_bayi.trim() === "") {
            alert("Masukkan Berat Badan Bayi saat Lahir")
        } else if (!("kms" in detailPatient) || detailPatient.kms.trim() === "") {
            alert("Apakah Memiliki KMS?")
        } else if (!("asuransi_kesehatan" in detailPatient) || detailPatient.asuransi_kesehatan.trim() === "") {
            alert("Apakah Memiliki Asuransi Kesehatan?")
        } else if (!("nama_bapak" in detailPatient) || detailPatient.nama_bapak.trim() === "") {
            alert("Masukkan Nama Bapak")
        } else if (!("nik_bapak" in detailPatient) || detailPatient.nik_bapak.trim() === "") {
            alert("Masukkan NIK Bapak")
        } else if (!("nama_ibu" in detailPatient) || detailPatient.nama_ibu.trim() === "") {
            alert("Masukkan Nama Ibu")
        } else if (!("nik_ibu" in detailPatient) || detailPatient.nik_ibu.trim() === "") {
            alert("Masukkan NIK Ibu")
        } else if (!("nomor_telepon" in detailPatient) || detailPatient.nomor_telepon.trim() === "") {
            alert("Masukkan Nomor Telepon")
        // } else if (!("family_card" in detailPatient)) {
        //     alert("Masukkan File Scan Kartu Keluarga")
        } else {
            props.onDataSave(detailPatient);
        }
    }

    const handleContinue = () => {
        props.onContinue();
    }

    const handleCancel = () => {
        props.onCancel();
    }

    const dataBabyForm = [
        {
            id: 1,
            title: 'Nama Balita',
            inputTag: <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="text" name='nama_balita' value={detailPatient.nama_balita || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='Nama Balita' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 2,
            title: 'NIK Balita',
            inputTag: <input className={"form-control input-without-stepper " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='nik_balita' value={detailPatient.nik_balita || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='NIK Balita' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 3,
            title: 'Jenis Kelamin',
            inputTag:   <select id="jenis_kelamin" className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} name='jenis_kelamin' value={detailPatient.jenis_kelamin || ""} onChange={inputEnabled ? handleChange : ()=>{}} required={inputEnabled ? true : false} disabled={inputEnabled ? false : true}>
                            <option disabled value="">Pilih Jenis Kelamin</option>
                            <option value="Laki-Laki">Laki-Laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>,
        },
        {
            id: 4,
            title: 'Tanggal Lahir',
            inputTag: <DatePicker onChange={inputEnabled ? onDateBirthChange : ()=>{}} value={dateBirthValue} format="dd-MM-y" className={inputEnabled ? 'input-box-enabled' : 'input-box-disabled'} required={inputEnabled ? true : false} disabled={inputEnabled ? false : true} />,
        },
        {
            id: 5,
            title: 'Alamat',
            inputTag: <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="text" name='alamat' value={detailPatient.alamat || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='Alamat' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 6,
            title: 'Golongan Darah',
            inputTag:   <select id="golongan_darah" className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} name='golongan_darah' value={detailPatient.golongan_darah || ""} onChange={inputEnabled ? handleChange : ()=>{}} required={inputEnabled ? true : false} disabled={inputEnabled ? false : true}>
                            <option disabled value="">Pilih Golongan Darah</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="AB">AB</option>
                            <option value="O">O</option>
                        </select>,
        },
        {
            id: 7,
            title: 'Berat Badan Bayi saat Lahir (g)',
            inputTag:   <div id="berat_badan_lahir_bayi" className="input-group">
                            <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='berat_badan_lahir_bayi' value={detailPatient.berat_badan_lahir_bayi || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='0' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} /> 
                            <span className={'input-group-text ' + (inputEnabled ? ' input-box-enabled ' : ' input-box-disabled ')}>gram</span>
                        </div>,
        },
        {
            id: 8,
            title: 'Apakah Memiliki KMS?',
            inputTag:   <select id="kms" className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} name='kms' value={detailPatient.kms || ""} onChange={inputEnabled ? handleChange : ()=>{}} required={inputEnabled ? true : false} disabled={inputEnabled ? false : true}>
                            <option disabled value="">Apakah Memiliki KMS?</option>
                            <option value="1">Punya</option>
                            <option value="0">Tidak Punya</option>
                        </select>,
        },
        {
            id: 9,
            title: 'Apakah Memiliki Asuransi Kesehatan?',
            inputTag:   <select id="asuransi_kesehatan" className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} name='asuransi_kesehatan' value={detailPatient.asuransi_kesehatan || ""} onChange={inputEnabled ? handleChange : ()=>{}} required={inputEnabled ? true : false} disabled={inputEnabled ? false : true}>
                            <option disabled value="">Apakah Memiliki Asuransi Kesehatan?</option>
                            <option value="1">Ada</option>
                            <option value="0">Tidak Ada</option>
                        </select>,
        },
        {
            id: 10,
            title: 'Nama Bapak',
            inputTag: <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="text" name='nama_bapak' value={detailPatient.nama_bapak || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='Nama Bapak' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 11,
            title: 'NIK Bapak',
            inputTag: <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='nik_bapak' value={detailPatient.nik_bapak || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='NIK Bapak' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 12,
            title: 'Nama Ibu',
            inputTag: <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="text" name='nama_ibu' value={detailPatient.nama_ibu || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='Nama Ibu' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 13,
            title: 'NIK Ibu',
            inputTag: <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='nik_ibu' value={detailPatient.nik_ibu || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='NIK Ibu' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 14,
            title: 'Nomor Telepon',
            inputTag: <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='nomor_telepon' value={detailPatient.nomor_telepon || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='Nomor Telepon' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 15,
            title: 'File Scan Kartu Keluarga',
            inputTag: inputEnabled ?
                            <input type="file" className="form-control " name="family_card" onChange={fileHandler} />
                        :
                            <p className="file-name">
                                {
                                    isFilePicked ?
                                    selectedFile.name :
                                    "Masukkan scan kartu keluarga"
                                }
                            </p>
        },
    ]

    return (
        <BabyForm 
            formData={dataBabyForm}
            buttonData={
                <>
                    {
                        inputEnabled ?
                            <button type="button" className="btn-first" onClick={handleSave}>
                            {
                                saveButtonLoading ?
                                    <LoadingButton />
                                :
                                    <span>Simpan</span>
                            }
                            </button>
                        :
                            <button type="button" className="btn-first" onClick={handleContinue}>Lanjutkan</button>
                    }
                    <button type="button" className="btn-second" onClick={handleCancel}>Batal</button>
                </>
            }
        />
    )
}