import React, { useState, useEffect } from "react";
import "./BabyForm.scss";
import calculateAge, { conversionDateToDefaultFormat, conversionMonthToNumber, conversionMonthToString } from "../../../_helpers/CalculateAge"

import DatePicker from 'react-date-picker'

import BabyForm from "./BabyForm";

export default function BabyProfileForm(props) {
    const inputEnabled = props.inputEnabled ?? true;
    const [detailPatient, setDetailPatient] = useState(props.detailPatient ?? {});

    const handleChange = (event) => 
    {
        const name = event.target.name;
        const value = event.target.value;
        setDetailPatient(values => ({...values, [name]: value}));
    }

    const [dateValue, setDateValue] = useState(conversionDateToDefaultFormat(detailPatient.date));
    const onDateChange = (val) => {
        setDateValue(val);
        setDetailPatient(values => ({...values, 'date': val.getDate() + " " + conversionMonthToString(val.getMonth()) + " " + val.getFullYear()}));
    }

    // tunggu revisi tanggal lahir
    const [age, setAge] = useState(calculateAge(detailPatient.tanggal_lahir));

    const dataBabyProfileForm = [
        {
            id: 1,
            title: 'Nama Balita',
            inputTag: <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="text" name='nama_balita' value={detailPatient.nama_balita || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='Nama Balita' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 2,
            title: 'NIK Balita',
            inputTag: <input className={"form-control input-without-stepper " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='nik_balita' value={detailPatient.nik_balita || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='NIK Balita' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} />,
        },
        {
            id: 3,
            title: 'Jenis Kelamin',
            inputTag:   <select id="jenis_kelamin" className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} name='jenis_kelamin' value={detailPatient.jenis_kelamin || ""} onChange={inputEnabled ? handleChange : ()=>{}} required={inputEnabled ? true : false} disabled={inputEnabled ? false : true}>
                            <option disabled value="">Pilih Jenis Kelamin</option>
                            <option value="Laki-Laki">Laki-Laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>,
        },
        {
            id: 4,
            title: 'Umur',
            inputTag:                   
                inputEnabled ?
                    <DatePicker onChange={onDateChange} value={dateValue} format="dd-MM-y" className="input-enabled" required /> 
                :
                    <div className="input-box-disabled form-control">
                        <span>
                            {
                                age === undefined 
                                    ? ""
                                    : age['Day(s)'] === 0
                                        ? age['AllMonth(s)'] + " Bulan"
                                        : (age['AllMonth(s)']+1) + " Bulan"
                            }
                        </span>
                        <span className="">
                            {
                                age === undefined 
                                    ? ""
                                    : " (" + age['Year(s)'] + " tahun " + age['Month(s)'] + " bulan " + age['Day(s)'] + " hari)"
                            }
                        </span>
                    </div>,
        },
    ]

    return (
        <BabyForm
            formData={dataBabyProfileForm}
        />
    )
}