import React, { useState, useEffect } from "react";
import "./Loading.scss";
import Responsive from "../../../_helpers/Responsive";

import ContentSectionBox from "../ContentSectionBox/ContentSectionBox";

export default function LoadingScreen(props) {
    const isFullScreen = props.isFullScreen ?? false;

    const { isDesktopOrLaptop, isTabletOrMobile } = Responsive();

    const textLoading = ' font-poppins ' + (isFullScreen ? 'font-size-32 font-weight-700' : 'font-size-14 font-weight-600') +' text-color-8 ';

    const loadingComponent = <div className="text-center">
                                <div className={"spinner-border color-spinner " + (isFullScreen && ' big-spinner ')} role="status"></div>
                                <div className={textLoading}>Loading</div>
                            </div>

    return (
        isFullScreen ?
            <div className="loading-full-screen-bg position-fixed vw-100 vh-100 d-flex justify-content-center align-items-center top-0 end-0">
                {loadingComponent}
            </div>
        :
            loadingComponent
    )
}
