import React, { useState, useEffect } from "react";
import "./Loading.scss";
import Responsive from "../../../_helpers/Responsive";

import ContentSectionBox from "../ContentSectionBox/ContentSectionBox";

export default function LoadingButton(props) {
    const isFullScreen = props.isFullScreen ?? false;

    const { isDesktopOrLaptop, isTabletOrMobile } = Responsive();

    const textLoading = ' font-poppins ' + (isFullScreen ? 'font-size-32 font-weight-700' : 'font-size-14 font-weight-600') +' text-color-8 ';

    return (
        <>
            <span className="spinner-border spinner-border-sm" role={"status"} aria-hidden="true"></span>
            <span className="sr-only"> Loading...</span>
        </>
    )
}
