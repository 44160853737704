import { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { fetchPatientById, getPatientById } from '../../../../redux/patient.slice';

export default function CalculationViewModel() {
    const dispatch = useDispatch();
    const detailPatient = useSelector(getPatientById) ?? {};
    const status = useSelector((state) => state.patient.status);
    const error = useSelector((state) => state.patient.error);

    const ref = useRef(null);

    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);

    const handleShow = (id) => {
        dispatch(fetchPatientById({id}));
    }

    const handleQuerySearch = (event) => 
    {
        const query = event.target.value;
        setKeyword(query);
    }

    const onFilterChange = (values) => {
        
    }

    useEffect(() => {
        if (status === 'get patient by id succeeded') {
            setShow(true);
        } else {
            setShow(false)
        }
    }, [status]);

    useEffect(() => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    }, [show]);

    return {
        onFilterChange,
        keyword,
        handleQuerySearch,
        handleShow,
        show,
        detailPatient,
        status,
        ref,
    }
}