import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

import "./Table.scss";

// import { useSelector, useDispatch } from 'react-redux';
// import { fetchAllPatients, fetchPatients, getPages } from '../../../redux/features/patients/patientsSlice'

export default function TableRecap(props) {
    const [dataPatients, setDataPatients] = useState([]);
    const [detailPatient, setDetailPatient] = useState({});
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(props.limitRow ?? 10);
    const [pages, setPages] = useState(0);

    const listDataPatient = {
        'baby_name' : 'Eren Yeager',
        'baby_nik' : '1234567899',
        'gender' : 'Laki-Laki',
        'date' : '04 Desember 2022',
        'address' : 'Jl. Telekomunikasi No. 1, Terusan Buahbatu, Bojongsoang...',
        'blood_type' : 'O',
        'baby_weight' : '3000',
        'is_KMS' : 'Punya',
        'is_health_insurance' : 'Ada',
        'father_name' : 'Grisha Yeager',
        'nik_bapak' : '1234567899',
        'mother_name' : 'Carla Yeager',
        'mother_nik' : '1234567899',
        'phone_number' : '082245718040',
    };

    const listDataPatients = [];
    
    for (let i = 0; i < 25; i++) {
        listDataPatients.push({...listDataPatient});
        listDataPatients[i].id = i;
        listDataPatients[i].baby_name += (i+1);
    }

    useEffect(() => {
        getDataPatient(props.searchByName ?? '');
        // dispatch(fetchPatients({page, limit}));
    }, [page, props.searchByName]);

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    const handleClickRow = (id) => {
        setDetailPatient(
            dataPatients.find((value) => value.id === id)
        );
        props.onClickRow(id);
    }

    const getDataPatient = async (querySearch) => {
        setPages(Math.ceil(listDataPatients.length/limit));
        setDataPatients(listDataPatients.slice(page*limit, (page+1)*limit));
    }

    const dataRecap = [
        
    ]

    const aaa = [
        'Balita yg ada di posyandu',
        'Balita yang terdaftar dan mempunyai KMS',
        'Balita yang berat badannya tetap',
        'Balita ditimbang bln ini tapi tdk di timbang bln lalu (status O)',
        'Balita yang baru pertama kali datang dan ditimbang',
        'Balita yang ditimbang bln ini',
        'Balita yg 2x tidak naik berat badannya atau tetap',
        'Balita yg ditimbang bln ini mencapai umur 36 bln',
        'Balita yg tdk hadir bln ini (status -)',
        'Balita yg BB ada dibawah garis merah',
        'Balita yang menerima kapsul vitamin A',
        'Balita yang mendapat ASI eksklusif',
    ]

    aaa.map((title, index) => 
        dataRecap.push(
            {
                id: index,
                title: title,
                '0-11 bulan laki-laki': 10,
                '0-11 bulan perempuan': 20,
                '12-23 bulan laki-laki': 30,
                '12-23 bulan perempuan': 40,
                '24-60 bulan laki-laki': 50,
                '24-60 bulan perempuan': 60,
                jumlah: 210,
            }
        )
    )

    return (
            <>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col" rowSpan={2}>Keterangan</th>
                            <th scope="col" colSpan={2} className='text-center'>0-11 Bulan</th>
                            <th scope="col" colSpan={2} className='text-center'>12-23 Bulan</th>
                            <th scope="col" colSpan={2} className='text-center'>24-60 Bulan</th>
                            <th scope="col" rowSpan={2} className='text-center'>Jumlah</th>
                        </tr>
                        <tr>
                            {
                                [...Array(3)].map(i => 
                                    <>
                                        <th scope="col" className='text-center'>L</th>
                                        <th scope="col" className='text-center'>P</th>
                                    </>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataRecap.map(data =>
                                <tr key={data.id}>
                                    <td>{data.title}</td>
                                    <td className='text-center'>{data['0-11 bulan laki-laki']}</td>
                                    <td className='text-center'>{data['0-11 bulan perempuan']}</td>
                                    <td className='text-center'>{data['12-23 bulan laki-laki']}</td>
                                    <td className='text-center'>{data['12-23 bulan perempuan']}</td>
                                    <td className='text-center'>{data['24-60 bulan laki-laki']}</td>
                                    <td className='text-center'>{data['24-60 bulan perempuan']}</td>
                                    <td className='text-center'>{data.jumlah}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
