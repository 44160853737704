// bulan spesial februari dan agustus
// lingkar kepala dan lingkar lengan atas hanya dapat di masukkan dibulan spesial
// vitamin a dan obat cacing hanya dapat di masukkan dibulan spesial

export default function isSpecialMonths() {
    const dateNow = new Date();

    const specialMonth = [1, 7];
    if (dateNow.getMonth() in specialMonth) {
        return true
    }

    return false
}