import React, { useState, useEffect } from "react";
import "./Filter.scss";

import { conversionMonthToString } from "../../../_helpers/CalculateAge";
import Responsive from "../../../_helpers/Responsive";

import { useSelector, useDispatch } from 'react-redux';
import { fetchUser, getRole, getRw, getDesa } from '../../../redux/auth.slice';

import LoadingScreen from "../LoadingComponent/LoadingScreen";

export default function PatientFilter(props) {
    const { isDesktopOrLaptop, isTabletOrMobile } = Responsive();

    const dispatch = useDispatch();
    const userRole = useSelector(getRole) ?? '';
    const userRw = useSelector(getRw) ?? '';
    const userDesa = useSelector(getDesa) ?? '';
    const status = useSelector((state) => state.auth.status);
    const error = useSelector((state) => state.auth.error);

    const dateNow = new Date();
    let monthNow = localStorage.getItem('filterMonth') ?? conversionMonthToString(dateNow.getMonth());
    let yearNow = localStorage.getItem('filterYear') ?? dateNow.getFullYear();
    const rw = localStorage.getItem('filterRw') ?? 'Semua';
    const desa = localStorage.getItem('filterDesa') ?? 'Semua';
    const [filter, setFilter] = useState({
        'filterRw': rw,
        'filterDesa': desa,
        'filterMonth': monthNow,
        'filterYear': yearNow,
    });

    const handleFilter = (event) => 
    {
        const name = event.target.name;
        const value = event.target.value;
        if (name === 'filterRw' || name === 'filterDesa') {
            setFilter(values => ({...values, ['filterRw']: userRw}));
            setFilter(values => ({...values, ['filterDesa']: userDesa}));
        } else {
            setFilter(values => ({...values, [name]: value}));
        }
        localStorage.setItem(name, value);
    }

    useEffect(() => {
        props.onFilterChange(filter);
    }, [filter])

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchUser());
        } else if (status === 'login succeeded') {
            dispatch(fetchUser());
        } else if (status === 'get user succeeded') {
            if (userRole === '0') {
                setFilter(values => ({...values, ['filterRw']: userRw}));
                setFilter(values => ({...values, ['filterDesa']: userDesa}));
            }
        }
    }, [status])

    const monthString = ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];
    const yearString = ["2020","2021","2022"];

    const classTextFilter = ' font-poppins font-size-12 font-weight-700 text-color-8 '

    return (
        <>
            {
                status === 'get user loading' ?
                    <LoadingScreen />
                :
                    status === 'failed' ?
                        <p>Gagal Tidak ada koneksi internet</p>
                    :
                        <div className={"patient-filter d-flex gap-4 justify-content-end " + (isTabletOrMobile && 'flex-wrap justify-content-between')}>
                            <select className={"input-enabled form-control select-patient-filter" + (isTabletOrMobile ? '-mobile' : '') + classTextFilter} name='filterRw' value={filter.filterRw || ""} onChange={handleFilter} required disabled={userRole === '0' ? true : false}>
                                <option value="Semua">Semua RW</option>
                                <option value="1">RW 1</option>
                                <option value="2">RW 2</option>
                                <option value="3">RW 3</option>
                            </select> 
                            <select className={"input-enabled form-control select-patient-filter" + (isTabletOrMobile ? '-mobile' : '') + classTextFilter} name='filterDesa' value={filter.filterDesa || ""} onChange={handleFilter} required disabled={userRole === '0' ? true : false}>
                                <option value="Semua">Semua Desa</option>
                                <option value="Jatiroto">Jatiroto</option>
                                <option value="Labruk Lor">Labruk Lor</option>
                                <option value="Banjarwaru">Banjarwaru</option>
                            </select> 
                            <select className={"input-enabled form-control select-patient-filter" + (isTabletOrMobile ? '-mobile' : '') + classTextFilter} name='filterMonth' value={filter.filterMonth || ""} onChange={handleFilter} required>
                                {
                                    monthString.map(value => <option key={value} value={value}>{value}</option>)
                                }
                            </select> 
                            <select className={"input-enabled form-control select-patient-filter" + (isTabletOrMobile ? '-mobile' : '') + classTextFilter} name='filterYear' value={filter.filterYear || ""} onChange={handleFilter} required>
                                {
                                    yearString.map(value => <option key={value} value={value}>{value}</option>)
                                }
                            </select> 
                        </div>
            }
        </>
    );
}