import React, { useState, useEffect } from "react";
import "./DashboardDashboard.scss";

import Responsive from "../../../../_helpers/Responsive";

export default function BoxKader(props) {
    const dataKader = props.dataKader ?? [];

    const { isDesktopOrLaptop, isTabletOrMobile } = Responsive();

    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">Jabatan</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Nomor HP</th>
                </tr>
            </thead>
            <tbody>
                {
                    dataKader.map(kader =>
                        <tr key={kader.id} className={kader.role === 'ketua' ? 'table-primary' : 'table-secondary'}>
                            <td className="text-capitalize">{kader.role}</td>
                            <td>{kader.nama}</td>
                            <td>{kader.nomor}</td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    );
}