import React, { useState, useEffect } from "react";
import useViewModel from "./ViewModel";
import { useNavigate } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';

import "./PatientDetailEdit.scss";
import Sidebar from "../../../components/Sidebar/Sidebar";
import BabyForm from "../../../components/BabyForm/BabyRegisterForm";
import InputSearch from "../../../components/InputSearch/InputSearch";
import TableProfilePatient from "../../../components/Table/TableProfilePatient";
import PatientFilter from "../../../components/Filter/PatientFilter";
import ContainerContents from "../../../components/ContainerContents/ContainerContents";
import LoadingScreen from "../../../components/LoadingComponent/LoadingScreen";

import registerPatientPlusIcon from '../../../Assets/Images/register-patient-plus.svg';

export default function PatientDetailEdit() {
    const {
        onFilterChange,
        keyword,
        handleQuerySearch,
        handleShow,
        show,
        handleClose,
        inputEnabled,
        handleEdit,
        detailPatient,
        handleSaveClose,
        status,
    } = useViewModel();
    
    const textButtonRegister = ' font-poppins font-size-12 font-weight-400 text-color-5 '

    return (
        <ContainerContents 
            activeMenu="patient"
            contentTitle="Register"
            contents={
                <>
                    <PatientFilter onFilterChange={onFilterChange} />
                    <div className="d-flex gap-4">
                        <InputSearch keyword={keyword} handleQuerySearch={handleQuerySearch} />
                        <a className="button-register-patient d-flex gap-1 align-items-center px-2 bg-color-1 text-decoration-none" href="/patient/register">
                            <p className={'mb-0 ' + textButtonRegister}>Tambah Pasien</p>
                            <img className='button-register-patient-icon' src={registerPatientPlusIcon} alt="" />
                        </a>
                    </div>
                    <TableProfilePatient
                        onClickRow={handleShow}
                        searchByName={keyword}
                    />
                    { 
                        status === 'get patient by id loading' ?
                            <LoadingScreen isFullScreen={true} />
                        :
                            // <!-- Modal -->
                            <Modal show={show} onHide={handleClose} backdrop={inputEnabled ? 'static' : 'true'} keyboard={false} dialogClassName="modal-custom-sizing"> 
                                <Modal.Header>
                                    <Modal.Title>Data Pasien</Modal.Title>
                                    {
                                        inputEnabled ? "" : <button type="button" className="btn-edit-data" onClick={handleEdit}>Edit</button>
                                    }
                                    
                                </Modal.Header>
                                <Modal.Body>
                                    <BabyForm 
                                        inputEnabled={inputEnabled}
                                        detailPatient={detailPatient}
                                        onDataSave={handleSaveClose}
                                        onCancel={handleClose}
                                        onContinue={handleClose}
                                        saveButtonLoading={status === 'update patient loading' ? true : false}
                                    />
                                </Modal.Body>
                            </Modal>
                    }
                </>
            }
        />
    );
}