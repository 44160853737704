import { useState, useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { getToken, login } from '../../../../redux/auth.slice';
import { conversionMonthToString } from "../../../../_helpers/CalculateAge";

export default function AccountLoginViewModel() {
    const loginStatus = useSelector((state) => state.auth.status);
    
    const dateNow = new Date();
    let monthNow = localStorage.getItem('filterMonth') ?? conversionMonthToString(dateNow.getMonth());
    let yearNow = localStorage.getItem('filterYear') ?? dateNow.getFullYear();
    const rw = localStorage.getItem('filterRw') ?? 'Semua';
    const desa = localStorage.getItem('filterDesa') ?? 'Semua';
    const [filter, setFilter] = useState({
        'filterRw': rw,
        'filterDesa': desa,
        'filterMonth': monthNow,
        'filterYear': yearNow,
    });

    const initDataKader = [
        {
            role: 'ketua',
            nama : 'Zeke Yaeger',
            nomor : '082245718040',
        },
        {
            role: 'anggota',
            nama : 'Naruto Uzumaki',
            nomor : '082245718040',
        },
        {
            role: 'anggota',
            nama : 'Sasuke Uchiha',
            nomor : '082245718040',
        },
        {
            role: 'anggota',
            nama : 'Neji Hyuga',
            nomor : '082245718040',
        },
        {
            role: 'anggota',
            nama : 'Rock Lee',
            nomor : '082245718040',
        },
    ]
    const [dataKader, setDataKader] = useState(initDataKader)

    const onFilterChange = (values) => {
        setFilter(values);
        const newDataKader = [...initDataKader];
        newDataKader[0].nama += (values.filterRw + values.filterDesa + values.filterMonth + values.filterYear);
        setDataKader(newDataKader);
    }

    useEffect(() => {
        if (loginStatus === 'login succeeded') {
            window.location.reload(false);
        }
    }, [loginStatus])

    return {
        onFilterChange,
        filter,
        dataKader,
    }
}