import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const dataAuth = {};
const token = localStorage.getItem('access_token');
if (token) {
    dataAuth['access_token'] = token
}

const initialState = {
    access_token: token,
    userData: {},
    status: 'idle',
    error: null
  };

export const login = createAsyncThunk('login/auth', async ({nik, password}) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "nik": nik,
            "password": password
        })
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions);
    const data = await response.json();
    return data;
});

export const logout = createAsyncThunk('logout/auth', async () => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
            "Authorization": "Bearer " + token ?? '',
        },
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, requestOptions);
    if (response.status !== 200) {
        localStorage.clear();
        return
    }
    const data = await response.json();
    return data;
});

export const fetchUser = createAsyncThunk('fetchUser/auth', async () => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Accept': 'application/json',
            "Authorization": "Bearer " + token ?? '',
        },
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/get/kader`, requestOptions);
    const data = await response.json();
    return data;
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(login.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.status = 'login succeeded';
                state.userData = action.payload.data.kader;
                state.access_token = action.payload.data.access_token;
                localStorage.setItem('access_token', action.payload.data.access_token);
            } else if (action.payload.meta.code === 500) {
                state.status = 'nik or password wrong';
                if ('password' in action.payload.data.error) {
                    state.status = action.payload.data.error.password[0] // 'The password field is required.'
                }
                if ('nik' in action.payload.data.error) {
                    state.status = action.payload.data.error.nik[0] // 'The nik field is required.'
                }
            }
        })
        builder.addCase(login.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        // -----------------------------------------------------------------------------------------
        builder.addCase(logout.pending, (state, action) => {
            state.status = 'logout loading';
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.status = 'logout succeeded';
                state.userData = {};
                state.access_token = '';
                localStorage.clear();
            }
        })
        builder.addCase(logout.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        // -----------------------------------------------------------------------------------------
        builder.addCase(fetchUser.pending, (state, action) => {
            state.status = 'get user loading';
        })
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.status = 'get user succeeded';
                state.userData = action.payload.data;
            }
        })
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
      },
});

export const getToken = (state) => state.auth.access_token;
export const getRole = (state) => state.auth.userData.role;
export const getRw = (state) => state.auth.userData.rw;
export const getDesa = (state) => state.auth.userData.desa;

export default authSlice.reducer;