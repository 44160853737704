import React, { useState, useEffect } from "react";
import "./BabyForm.scss";
import isSpecialMonths from "../../../_helpers/SpecialMonths";

import BabyForm from "./BabyForm";

export default function BabyVaccinationForm(props) {
    const saveButtonLoading = props.saveButtonLoading ?? false;
    const inputEnabled = props.inputEnabled ?? true;
    const showCancelButton = props.showCancelButton ?? true;
    const [vaccination, setVaccination] = useState(props.detailPatient ?? {});

    const handleChange = (event) => 
    {
        const name = event.target.name;
        const value = event.target.checked;
        
        if (name === 'vitamin_a' || name === 'obat_cacing') {
            if (isSpecialMonths()) {
                setVaccination(values => ({...values, [name]: value}));
            }
        } else {
            setVaccination(values => ({...values, [name]: value}));
        }
    }

    const handleSave = () => {
        props.onDataSave(vaccination);
    };

    const handleCancel = () => {
        props.onCancel();
    }

    const dataBabyVaccinationForm = [
        {
            id: 1,
            title: 'Vitamin A',
            inputTagId: 'vitamin_a_checkbox',
            inputTag: <input id="vitamin_a_checkbox" className="form-check-input " type="checkbox" name='vitamin_a' checked={vaccination.vitamin_a || false} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} disabled={inputEnabled && isSpecialMonths() ? false : true} />,
        },
        {
            id: 2,
            title: 'HB 0',
            inputTagId: 'hb_checkbox',
            inputTag: <input id="hb_checkbox" className="form-check-input " type="checkbox" name='hb' checked={vaccination.hb || false} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} disabled={inputEnabled && isSpecialMonths() ? false : true} />,
        },
        {
            id: 3,
            title: 'Polio',
            inputTagId: 'polio_checkbox',
            inputTag: <input id="polio_checkbox" className="form-check-input " type="checkbox" name='polio' checked={vaccination.polio || false} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} disabled={inputEnabled && isSpecialMonths() ? false : true} />,
        },
        {
            id: 4,
            title: 'Campak',
            inputTagId: 'campak_checkbox',
            inputTag: <input id="campak_checkbox" className="form-check-input " type="checkbox" name='campak' checked={vaccination.campak || false} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} disabled={inputEnabled && isSpecialMonths() ? false : true} />,
        },
        {
            id: 5,
            title: 'Oralit',
            inputTagId: 'oralit_checkbox',
            inputTag: <input id="oralit_checkbox" className="form-check-input " type="checkbox" name='oralit' checked={vaccination.oralit || false} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} disabled={inputEnabled && isSpecialMonths() ? false : true} />,
        },
        {
            id: 6,
            title: 'BCG',
            inputTagId: 'bcg_checkbox',
            inputTag: <input id="bcg_checkbox" className="form-check-input " type="checkbox" name='bcg' checked={vaccination.bcg || false} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} disabled={inputEnabled && isSpecialMonths() ? false : true} />,
        },
        {
            id: 7,
            title: 'DPT/HB',
            inputTagId: 'dpt_checkbox',
            inputTag: <input id="dpt_checkbox" className="form-check-input " type="checkbox" name='dpt' checked={vaccination.dpt || false} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} disabled={inputEnabled && isSpecialMonths() ? false : true} />,
        },
        {
            id: 8,
            title: 'Obat Cacing',
            inputTagId: 'obat_cacing_checkbox',
            inputTag: <input id="obat_cacing_checkbox" className="form-check-input " type="checkbox" name='obat_cacing' checked={vaccination.obat_cacing || false} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} disabled={inputEnabled && isSpecialMonths() ? false : true} />,
        },
        // {
        //     id: 2,
        //     title: 'Tinggi Badan',
        //     inputTag:   <div className="input-group">
        //                     <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='tinggi_badan' value={patientMeasurement.tinggi_badan || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='0' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} /> 
        //                     <span className={'input-group-text ' + (inputEnabled ? ' input-box-enabled ' : ' input-box-disabled ')}>gram</span>
        //                 </div>,
        // },
        // {
        //     id: 3,
        //     title: 'Lingkar Kepala',
        //     inputTag:   <div className="input-group">
        //                     <input className={"form-control " + (inputEnabled && isSpecialMonths() ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='lingkar_kepala' value={patientMeasurement.lingkar_kepala || ""} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} placeholder='0' required={inputEnabled && isSpecialMonths() ? true : false} readOnly={inputEnabled && isSpecialMonths() ? false : true} /> 
        //                     <span className={'input-group-text ' + (inputEnabled && isSpecialMonths() ? ' input-box-enabled ' : ' input-box-disabled ')}>cm</span>
        //                 </div>,
        // },
        // {
        //     id: 4,
        //     title: 'Lingkar Lengan Atas',
        //     inputTag:   <div className="input-group">
        //                     <input className={"form-control " + (inputEnabled && isSpecialMonths() ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='lingkar_lengan_atas' value={patientMeasurement.lingkar_lengan_atas || ""} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} placeholder='0' required={inputEnabled && isSpecialMonths() ? true : false} readOnly={inputEnabled && isSpecialMonths() ? false : true} /> 
        //                     <span className={'input-group-text ' + (inputEnabled && isSpecialMonths() ? ' input-box-enabled ' : ' input-box-disabled ')}>cm</span>
        //                 </div>,
        // },
        // {
        //     id: 5,
        //     title: 'Jenis Kelamin',
        //     inputTag:   <select id="asi_eksklusif" className={"form-control " + (inputEnabled && (age['AllMonth(s)'] <= 6) ? 'input-box-enabled' : 'input-box-disabled')} name='asi_eksklusif' value={patientMeasurement.asi_eksklusif || ""} onChange={inputEnabled && (age['AllMonth(s)'] <= 6) ? handleChange : ()=>{}} required={inputEnabled && (age['AllMonth(s)'] <= 6) ? true : false} disabled={inputEnabled && (age['AllMonth(s)'] <= 6) ? false : true}>
        //                     <option disabled value="">Pilih Opsi</option>
        //                     <option value="1">Iya</option>
        //                     <option value="0">Tidak</option>
        //                 </select>,
        // },
    ]

    return (
        <>
            <p className="container-form-title">{vaccination.date}</p>
            <div className="d-flex gap-4 flex-wrap">
                {
                    dataBabyVaccinationForm.map(data =>
                        <div className="d-flex gap-3" style={{ width: '170px' }}>
                            {data.inputTag}
                            <label className="form-check-label" htmlFor={data.inputTagId}>
                                {data.title}
                            </label>
                        </div>
                    )
                }
                {/* <div className="content-form-body content-form-body-width flex-direction-row align-item-center">
                    {
                        inputEnabled && isSpecialMonths() ?
                            <input id="vitamin_a_checkbox" className="form-check-input" type="checkbox" name="vitamin_a" checked={vaccination.vitamin_a || false}  onChange={handleChange} />
                        :
                            <input className="form-check-input" type="checkbox" name="vitamin_a" checked={vaccination.vitamin_a || false} disabled />
                    }
                    <label className="form-check-label" htmlFor="vitamin_a_checkbox">
                        Vitamin A
                    </label>
                </div>
                <div className="content-form-body content-form-body-width flex-direction-row align-item-center">
                    {
                        inputEnabled ?
                            <input id="hb_checkbox" className="form-check-input" type="checkbox" name="hb" checked={vaccination.hb || false} onChange={handleChange} />
                        :
                            <input className="form-check-input" type="checkbox" name="hb" checked={vaccination.hb || false} disabled />
                    }
                    <label className="form-check-label" htmlFor="hb_checkbox">
                        HB 0
                    </label>
                </div>
                <div className="content-form-body content-form-body-width flex-direction-row align-item-center">
                    {
                        inputEnabled ?
                            <input id="polio_checkbox" className="form-check-input" type="checkbox" name="polio" checked={vaccination.polio || ""}  onChange={handleChange} />
                        :
                            <input className="form-check-input" type="checkbox" name="polio" checked={vaccination.polio || ""} disabled />
                    }
                    <label className="form-check-label" htmlFor="polio_checkbox">
                        Polio
                    </label>
                </div>
                <div className="content-form-body content-form-body-width flex-direction-row align-item-center">
                    {
                        inputEnabled ?
                            <input id="campak_checkbox" className="form-check-input" type="checkbox" name="campak" checked={vaccination.campak || ""}  onChange={handleChange} />
                        :
                            <input className="form-check-input" type="checkbox" name="campak" checked={vaccination.campak || ""} disabled />
                    }
                    <label className="form-check-label" htmlFor="campak_checkbox">
                        Campak
                    </label>
                </div>
                <div className="content-form-body content-form-body-width flex-direction-row align-item-center">
                    {
                        inputEnabled ?
                            <input id="oralit_checkbox" className="form-check-input" type="checkbox" name="oralit" checked={vaccination.oralit || ""}  onChange={handleChange} />
                        :
                            <input className="form-check-input" type="checkbox" name="oralit" checked={vaccination.oralit || ""} disabled />
                    }
                    <label className="form-check-label" htmlFor="oralit_checkbox">
                        Oralit
                    </label>
                </div>
                <div className="content-form-body content-form-body-width flex-direction-row align-item-center">
                    {
                        inputEnabled ?
                            <input id="bcg_checkbox" className="form-check-input" type="checkbox" name="bcg" checked={vaccination.bcg || ""}  onChange={handleChange} />
                        :
                            <input className="form-check-input" type="checkbox" name="bcg" checked={vaccination.bcg || ""} disabled />
                    }
                    <label className="form-check-label" htmlFor="bcg_checkbox">
                        BCG
                    </label>
                </div>
                <div className="content-form-body content-form-body-width flex-direction-row align-item-center">
                    {
                        inputEnabled ?
                            <input id="dpt_checkbox" className="form-check-input" type="checkbox" name="dpt" checked={vaccination.dpt || ""}  onChange={handleChange} />
                        :
                            <input className="form-check-input" type="checkbox" name="dpt" checked={vaccination.dpt || ""} disabled />
                    }
                    <label className="form-check-label" htmlFor="dpt_checkbox">
                        DPT/HB
                    </label>
                </div>
                <div className="content-form-body content-form-body-width flex-direction-row align-item-center">
                    {
                        inputEnabled && isSpecialMonths() ?
                            <input id="obat_cacing_checkbox" className="form-check-input" type="checkbox" name="obat_cacing" checked={vaccination.obat_cacing || ""}  onChange={handleChange} />
                        :
                            <input className="form-check-input" type="checkbox" name="obat_cacing" checked={vaccination.obat_cacing || ""} disabled />
                    }
                    <label className="form-check-label" htmlFor="obat_cacing_checkbox">
                        Obat Cacing
                    </label>
                </div> */}
                <button type="button" className="btn-first mt-3" onClick={handleSave}>
                    {
                        saveButtonLoading ?
                            <>
                                <span className="spinner-border spinner-border-sm" role={"status"} aria-hidden="true"></span>
                                <span className="sr-only"> Loading...</span>
                            </>
                        :
                            <span>Simpan</span>
                    }
                </button>
                {
                    showCancelButton ?
                        <button type="button" className="btn-second" onClick={handleCancel}>Batal</button>
                    :
                        <></>
                }
            </div>
        </>
    )
}
