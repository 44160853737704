import React from "react";
import "./InputSearch.scss";

import searchIcon from '../../Assets/Images/Search.svg';

class InputSearch extends React.Component {
    render() {
        const keyword = this.props.keyword ?? "";
        const handleQuerySearch = this.props.handleQuerySearch;

        const textInputSearch = ' font-poppins font-size-12 font-weight-400 text-color-9 '

        return (
            <form className="search-group flex-fill d-flex bg-color-5 align-items-center">
                <input className={"input-search flex-fill border-0 px-3 bg-transparent" + textInputSearch} type="text" name='search' value={keyword || ""} onChange={handleQuerySearch} placeholder='Cari Nama' />
                <span className="">
                    <img className='pe-3' src={searchIcon} alt="" />
                </span>
            </form>
        );
    }
}

export default InputSearch;