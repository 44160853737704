import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getToken } from '../redux/auth.slice';
import AccountLogin from '../Presentation/Views/Account/Login/AccountLogin';

export { PrivateRoute };

function PrivateRoute({ children }) {
    const token = useSelector(getToken);
    
    if (!token) {
        if (children.type === AccountLogin) {
            return children;
        } else {
            // not logged in so redirect to login page with the return url
            return <Navigate to="/login" />
        }
    } else {
        if (children.type === AccountLogin) {
            return <Navigate to="/dashboard" />
        } else {
            // authorized so return child components
            return children;
        }
    }
}