import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

import "./Table.scss";

import { useSelector, useDispatch } from 'react-redux';
import { fetchAllPatients, fetchAllPatientsBySearch, getPages, getAllPatients } from '../../../redux/patient.slice';

import LoadingScreen from "../LoadingComponent/LoadingScreen";

export default function TableProfilePatient(props) {
    const dispatch = useDispatch();
    const pages = useSelector(getPages) ?? 0;
    const dataPatients = useSelector(getAllPatients) ?? [];
    const status = useSelector((state) => state.patient.status);
    const error = useSelector((state) => state.patient.error);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(props.limitRow ?? 10);

    useEffect(() => {
        if (props.searchByName === '') {
            dispatch(fetchAllPatients({page, limit}));
        } else {
            const searchByName = props.searchByName;
            dispatch(fetchAllPatientsBySearch({page, searchByName, limit}));
        }
    }, [page, props.searchByName]);

    useEffect(() => {
        if (status === 'update patient succeeded') {
            dispatch(fetchAllPatients({page, limit}));
        }
    }, [status]);

    const changePage = ({ selected }) => {
        setPage(selected + 1);
    };

    const handleClickRow = (id) => {
        props.onClickRow(id);
    }

    return (
        <>
            {
                status === 'get all patient loading' ?
                    <LoadingScreen />
                :
                    <div className="table-responsive">
                        <table className="table table-hover table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Nama</th>
                                    <th scope="col">NIK</th>
                                    <th scope="col">Jenis Kelamin</th>
                                    <th scope="col">Tanggal Lahir</th>
                                    <th scope="col">Golongan Darah</th>
                                    <th scope="col">Berat Badan Saat Lahir</th>
                                    <th scope="col">Nama Bapak</th>
                                    <th scope="col">NIK Bapak</th>
                                    <th scope="col">Nama Ibu</th>
                                    <th scope="col">NIK Ibu</th>
                                    <th scope="col">Alamat</th>
                                    <th scope="col">Nomor Telepon</th>
                                    <th scope="col">Asuransi Kesehatan</th>
                                    <th scope="col">KMS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataPatients.map((patient) => 
                                        <tr className="clickable-table-row" key={patient.id} onClick={() => handleClickRow(patient.id)} data-bs-target="#exampleModal" data-bs-toggle="modal">
                                                <td>{patient.nama_balita}</td>
                                                <td>{patient.nik_balita}</td>
                                                <td>{patient.jenis_kelamin}</td>
                                                <td>{patient.tanggal_lahir}</td>
                                                <td>{patient.golongan_darah}</td>
                                                <td>{patient.berat_badan_lahir_bayi}</td>
                                                <td>{patient.nama_bapak}</td>
                                                <td>{patient.nik_bapak}</td>
                                                <td>{patient.nama_ibu}</td>
                                                <td>{patient.nik_ibu}</td>
                                                <td>{patient.alamat}</td>
                                                <td>{patient.nomor_telepon}</td>
                                                <td>{patient.kms ? 'Ada' : 'Tidak Ada'}</td>
                                                <td>{patient.asuransi_kesehatan ? 'Punya' : 'Tidak Punya'}</td>
                                            </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
            }

            <nav
                className="pagination"
                role="navigation"
                aria-label="pagination"
            >
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pages}
                    onPageChange={changePage}
                    containerClassName={"pagination-list"}
                    pageLinkClassName={"pagination-link"}
                    previousClassName={"container-previous"}
                    nextClassName={"container-next"}
                    previousLinkClassName={"pagination-previous"}
                    nextLinkClassName={"pagination-next"}
                    activeLinkClassName={"pagination-link is-active"}
                    disabledLinkClassName={"pagination-link is-disabled"}
                    breakClassName={"pagination-break"}
                />
            </nav>
        </>
    )
}
