import React, { useState, useEffect } from "react";
import useViewModel from "./ViewModel";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';

import "./Recap.scss";

import Sidebar from "../../../components/Sidebar/Sidebar";
import InputSearch from "../../../components/InputSearch/InputSearch";
import BabyProfileForm from "../../../components/BabyForm/BabyProfileForm";
import BabyVaccinationForm from "../../../components/BabyForm/BabyVaccinationForm";
import TableHistoryVaccination from "../../../components/Table/TableHistoryVaccination";
import TableRecap from "../../../components/Table/TableRecap";
import TablePatientRecap from "../../../components/Table/TablePatientRecap";
import VaccineCertificate from "../../../components/VaccineCertificate/VaccineCertificate";
import PatientFilter from "../../../components/Filter/PatientFilter";
import ContainerContents from "../../../components/ContainerContents/ContainerContents";
import LoadingScreen from "../../../components/LoadingComponent/LoadingScreen";
import ContentBox from "../../../components/ContentBox/ContentBox";

export default function Recap() {
    const [dataPatients, setDataPatients] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [detailPatient, setDetailPatient] = useState({});

    const [show, setShow] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);

    const handleShow = (id) => {
        setDetailPatient(
            dataPatients.find((value) => value.id === id)
        );
        // if (pasien sudah lengkap vaksin) {
        //     tampilkan vaksin
        // }
        // setShowCertificate(true);
        setShow(true);
    }

    useEffect(() => {
        getDataPatient();
    }, [page, keyword]);

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    const handleQuerySearch = (event) => 
    {
        const query = event.target.value;
        setKeyword(query);
    }

    const listDataPatient = {
        'baby_name' : 'Eren Yeager',
        'baby_nik' : '1234567899',
        'gender' : 'Laki-Laki',
        'date' : '04 Juli 2022',
        'address' : 'Jl. Telekomunikasi No. 1, Terusan Buahbatu, Bojongsoang...',
        'blood_type' : 'O',
        'baby_weight' : '3000',
        'is_KMS' : 'Punya',
        'is_health_insurance' : 'Ada',
        'father_name' : 'Grisha Yeager',
        'nik_bapak' : '1234567899',
        'mother_name' : 'Carla Yeager',
        'mother_nik' : '1234567899',
        'phone_number' : '082245718040',
    };

    const listDataPatients = [];
    
    for (let i = 0; i < 25; i++) {
        listDataPatients.push({...listDataPatient});
        listDataPatients[i].id = i;
        listDataPatients[i].baby_name += (i+1);
    }

    const getDataPatient = async () => {
        setPages(Math.ceil(listDataPatients.length/limit));
        // get data dari api
        setDataPatients(listDataPatients.slice(page*limit, (page+1)*limit));
    }

    const handleSave = () => {
        
    }

    const onDataChange = (data) => {
        // simpan pengukuran ke api detail data
        alert("Data berhasil disimpan");
        getDataPatient();
    }

    const handleClose = () => {
        setShowCertificate(false);
    }

    const onFilterChange = (values) => {
        
    }

    const sendReport = () => {
        
    }

    const downloadReport = () => {
        
    }

    return (
        // <div className="sidebar-and-content">
        //     <Sidebar activeMenu="recap"/>
        //     <div className="contents">
        //         <div className="contents-box contents-box-inline">
        //             <p className="content-title">Rekap</p>
        //             <PatientFilter onFilterChange={onFilterChange} />
        //         </div>
        //         <div className="contents-box">
        //             <TableRecap
        //                 onClickRow={handleShow}
        //                 searchByName={keyword}
        //                 limitRow={5}
        //             />
        //         </div>
        //         <div className="contents-box">
        //             <TablePatientRecap
        //                 onClickRow={handleShow}
        //                 searchByName={keyword}
        //                 limitRow={7}
        //             />
        //         </div>
        //         <div className="contents-box d-flex flex-column gap-3">
        //             <button type="button" className="btn-first" onClick={sendReport}>Kirim Laporan</button>
        //             <button type="button" className="btn-second" onClick={downloadReport}>Download Laporan</button>
        //         </div>
        //     </div>
        // </div>
        <ContainerContents 
            activeMenu="recap"
            contentTitle="Rekap"
            contents={
                <>
                    <PatientFilter onFilterChange={onFilterChange} />
                    <TableRecap
                        onClickRow={handleShow}
                        searchByName={keyword}
                        limitRow={5}
                    />
                    <TablePatientRecap
                        onClickRow={handleShow}
                        searchByName={keyword}
                        limitRow={7}
                    />
                    <button type="button" className="btn-first" onClick={sendReport}>Kirim Laporan</button>
                    <button type="button" className="btn-second" onClick={downloadReport}>Download Laporan</button>
                </>
            }
        />
    );
}