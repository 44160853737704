import { useState, useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { fetchPatientById, getPatientById, updatePatients } from '../../../../redux/patient.slice';

export default function DetailEditViewModel() {
    const dispatch = useDispatch();
    const detailPatient = useSelector(getPatientById) ?? {};
    const status = useSelector((state) => state.patient.status);
    const error = useSelector((state) => state.patient.error);

    const [keyword, setKeyword] = useState("");
    const [inputEnabled, setInputEnabled] = useState(false);
    const handleEdit = () => setInputEnabled(true);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setInputEnabled(false);
    }
    
    const handleSaveClose = (newDataPatient) => {
        dispatch(updatePatients({...newDataPatient}));
    }

    const handleShow = (id) => {
        dispatch(fetchPatientById({id}));
    }

    const handleQuerySearch = (event) => 
    {
        const query = event.target.value;
        setKeyword(query);
    }

    const onFilterChange = (values) => {
        
    }

    useEffect(() => {
        if (status === 'get patient by id succeeded') {
            setShow(true);
        } else if (status === 'update patient succeeded') {
            setShow(false);
            setInputEnabled(false);
        }
    }, [status]);

    return {
        onFilterChange,
        keyword,
        handleQuerySearch,
        handleShow,
        show,
        handleClose,
        inputEnabled,
        handleEdit,
        detailPatient,
        handleSaveClose,
        status,
    }
}