import React, { useState, useEffect } from "react";
import "./ContentSectionBox.scss";
import Responsive from "../../../_helpers/Responsive";

export default function ContentSectionBox(props) {
    const value = props.value ?? '';
    const title = props.title ?? '';

    const { isDesktopOrLaptop, isTabletOrMobile } = Responsive();

    const textContentSectionValueDekstop = ' font-poppins font-size-32 font-weight-700 text-color-8 '
    const textContentSectionValueMobile = ' font-poppins font-size-20 font-weight-700 text-color-8 '
    const textContentSectionTitleDekstop = ' font-poppins font-size-14 font-weight-700 text-color-8 '
    const textContentSectionTitleMobile = ' font-poppins font-size-10 font-weight-700 text-color-8 '

    return (
        <div key={title} className={"d-flex flex-row align-items-center justify-content-start content-section" + (isTabletOrMobile ? ' mobile ' : ' dekstop ')}>
            <p className={"content-section-value m-0 " + (isTabletOrMobile ? ' mobile ' + textContentSectionValueMobile : ' dekstop ' + textContentSectionValueDekstop)}>{value}</p>
            <p className={"content-section-title mb-0 text-start " + (isTabletOrMobile ? ' mobile ms-2 ' + textContentSectionTitleMobile : ' dekstop ms-3 ' + textContentSectionTitleDekstop)}>{title}</p>
        </div>
    )
}
