import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { registerPatient } from '../../../../redux/patient.slice';

export default function PatientRegisterViewModel() {
    const dispatch = useDispatch();
    const registerPatientStatus = useSelector((state) => state.patient.status);
    const error = useSelector((state) => state.patient.error);

    const navigate = useNavigate();

    const handleSave = (detailPatient) => {
        dispatch(registerPatient({...detailPatient}));
    }

    const handleBack = () =>{ 
        let path = `/patient`; 
        navigate(path);
    }

    const handleContinue = () =>{ 
        let path = `/patient`; 
        navigate(path);
    }

    // useEffect(() => {
    //     if (registerPatientStatus === 'register patient succeeded') {
    //         let path = `/patient`; 
    //         navigate(path);
    //     }
    // }, [registerPatientStatus]);


    return {
        handleSave,
        handleBack,
        registerPatientStatus,
        handleContinue,
    }
}