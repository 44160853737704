import React, { useState, useEffect } from "react";
import useViewModel from "./ViewModel";
import { useNavigate, useParams } from "react-router-dom";
import "./DashboardDashboard.scss";
import Sidebar from "../../../components/Sidebar/Sidebar";
import PatientFilter from "../../../components/Filter/PatientFilter";
import ContentSectionBox from "../../../components/ContentSectionBox/ContentSectionBox";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ContainerContents from "../../../components/ContainerContents/ContainerContents";
import BoxKader from "./BoxKader";

import Responsive from "../../../../_helpers/Responsive";

export default function DashboardDashboard() {
    const { onFilterChange, filter, dataKader } = useViewModel();
    const { isDesktopOrLaptop, isTabletOrMobile } = Responsive();

    const dataDashboard = [
        {
            id: 1,
            title: null,
            data: [
                {
                    id: 1,
                    value : '45',
                    title: 'Jumlah Balita yang terdaftar',
                },
                {
                    id: 2,
                    value : '45',
                    title: 'Jumlah Balita yang datang',
                },
                {
                    id: 3,
                    value : '45',
                    title: 'Jumlah Balita yang memiliki KMS',
                },
                {
                    id: 4,
                    value : '45',
                    title: 'Jumlah Balita yang BB nya Naik',
                },
                {
                    id: 5,
                    value : '45',
                    title: 'Jumlah Balita yang BB nya Tetap',
                },
                {
                    id: 6,
                    value : '45',
                    title: 'Jumlah Balita yang BB nya Turun',
                },
            ]
        },
        {
            id: 2,
            title: "Berat Badan Menurut Umur",
            data: [
                {
                    id: 1,
                    value : '45',
                    title: 'Sangat Kurang',
                },
                {
                    id: 2,
                    value : '45',
                    title: 'Kurang',
                },
                {
                    id: 3,
                    value : '45',
                    title: 'Normal',
                },
                {
                    id: 4,
                    value : '45',
                    title: 'Lebih',
                },
            ]
        },
        {
            id: 3,
            title: "Tinggi Badan menurut Umur",
            data: [
                {
                    id: 1,
                    value : '45',
                    title: 'Sangat Pendek',
                },
                {
                    id: 2,
                    value : '45',
                    title: 'Pendek',
                },
                {
                    id: 3,
                    value : '45',
                    title: 'Normal',
                },
                {
                    id: 4,
                    value : '45',
                    title: 'Tinggi',
                },
            ]
        },
        {
            id: 4,
            title: "Berat Badan menurut Tinggi Badan",
            data: [
                {
                    id: 1,
                    value : '45',
                    title: 'Gizi Buruk',
                },
                {
                    id: 2,
                    value : '45',
                    title: 'Gizi Kurang',
                },
                {
                    id: 3,
                    value : '45',
                    title: 'Gizi Baik',
                },
                {
                    id: 4,
                    value : '45',
                    title: 'Beriziko Gizi Lebih',
                },
                {
                    id: 5,
                    value : '45',
                    title: 'Gizi Lebih',
                },
                {
                    id: 6,
                    value : '45',
                    title: 'Obesitas',
                },
            ]
        },
        {
            id: 5,
            title: "Indeks Massa Tubuh menurut Umur",
            data: [
                {
                    id: 1,
                    value : '45',
                    title: 'Gizi Buruk',
                },
                {
                    id: 2,
                    value : '45',
                    title: 'Gizi Kurang',
                },
                {
                    id: 3,
                    value : '45',
                    title: 'Gizi Baik',
                },
                {
                    id: 4,
                    value : '45',
                    title: 'Beriziko Gizi Lebih',
                },
                {
                    id: 5,
                    value : '45',
                    title: 'Gizi Lebih',
                },
                {
                    id: 6,
                    value : '45',
                    title: 'Obesitas',
                },
            ]
        },
    ]

    return (
        <ContainerContents 
            activeMenu="dashboard"
            contentTitle="Dashboard"
            contents={
                <>
                    <PatientFilter onFilterChange={onFilterChange} />
                    {
                        filter.filterRw !== 'Semua' && filter.filterDesa !== 'Semua' ?
                            <BoxKader dataKader={dataKader} />
                        :
                            <></>
                    }
                    {
                        dataDashboard.map(data => 
                            <ContentBox 
                                title={data.title} 
                                dataContent={
                                    [
                                        data.data.map(data =>
                                            <ContentSectionBox
                                                value={data.value}
                                                title={data.title}
                                            />    
                                        )
                                    ]
                                } 
                            />
                        )
                    }
                </>
            }
        />
    );
}