import React, { useState, useEffect } from "react";
import useViewModel from "./ViewModel";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./Medical.scss";
import Sidebar from "../../../components/Sidebar/Sidebar";
import InputSearch from "../../../components/InputSearch/InputSearch";
import BabyProfileForm from "../../../components/BabyForm/BabyProfileForm";
import BabyVaccinationForm from "../../../components/BabyForm/BabyVaccinationForm";
import TableHistoryVaccination from "../../../components/Table/TableHistoryVaccination";
import Modal from 'react-bootstrap/Modal';
import VaccineCertificate from "../../../components/VaccineCertificate/VaccineCertificate";
import TableProfilePatient from "../../../components/Table/TableProfilePatient";
import PatientFilter from "../../../components/Filter/PatientFilter";
import ContainerContents from "../../../components/ContainerContents/ContainerContents";
import LoadingScreen from "../../../components/LoadingComponent/LoadingScreen";
import ContentBox from "../../../components/ContentBox/ContentBox";

export default function Medical() {
    const {
        onFilterChange,
        keyword,
        handleQuerySearch,
        handleShow,
        show,
        detailPatient,
        handleSaveMedis,
        handleCloseCertificate,
        showCertificate,
        status,
        ref
    } = useViewModel();

    return (
        // <div className="sidebar-and-content">
        //     <Sidebar activeMenu="medical"/>
        //     <div className="contents">
        //         <div className="contents-box contents-box-inline">
        //             <p className="content-title">Medis</p>
        //             <PatientFilter onFilterChange={onFilterChange} />
        //         </div>
        //         <div className="contents-box">
        //             <div className="content">
        //                 <InputSearch keyword={keyword} handleQuerySearch={handleQuerySearch} />
        //             </div>
        //         </div>
        //         <div className="contents-box">
        //             <TableProfilePatient
        //                 onClickRow={handleShow}
        //                 searchByName={keyword}
        //                 limitRow={5}
        //             />
        //         </div>
        //         {
        //             status === 'get patient by id loading' ?
        //                 <div className="loading-full-screen">
        //                     <div className="text-center">
        //                         <div className="spinner-border big-spinner" role="status"></div>
        //                         <div className="loading-full-screen-text">Loading</div>
        //                     </div>
        //                 </div>
        //             :
        //                 <div key={detailPatient.id} ref={ref} style={{ display: show ? 'block' : 'none' }}>
        //                     <div className="contents-box">
        //                         <p className="content-title">Profil</p>
        //                         <div className="content content-profile">
        //                             <BabyProfileForm 
        //                                 inputEnabled={false} 
        //                                 detailPatient={detailPatient}
        //                             />
        //                         </div>
        //                     </div>
        //                     <div className="contents-box">
        //                         <p className="sub-content-title">Layanan yang diberikan</p>
        //                         <div className="content">
        //                             <BabyVaccinationForm 
        //                                 showCancelButton={false}
        //                                 onDataSave={handleSaveMedis}
        //                                 saveButtonLoading={status === 'update patient vaccination loading' ? true : false}
        //                                 detailPatient={detailPatient}
        //                             />
        //                         </div>
        //                     </div>
        //                     <div className="contents-box">
        //                         <p className="sub-content-title">Riwayat</p>
        //                         <div className="content">
        //                             {/* <TableHistoryVaccination 
        //                                 idPatient={detailPatient.id}
        //                             /> */}
        //                         </div>
        //                     </div>
        //                 </div>
        //         }
        //     </div>
        //     <Modal show={showCertificate} onHide={handleCloseCertificate}> 
        //         <Modal.Header>
        //             <Modal.Title>Surat Keterangan Imunisasi/Vaksin Posyandu</Modal.Title>
        //         </Modal.Header>
        //         <Modal.Body>
        //             <VaccineCertificate
        //                 onCancel={handleCloseCertificate}
        //                 fileName={detailPatient.baby_nik}
        //             />
        //         </Modal.Body>
        //         <Modal.Footer>
                    
        //         </Modal.Footer>
        //     </Modal>
        // </div>
        <ContainerContents 
            activeMenu="medical"
            contentTitle="Medis"
            contents={
                <>
                    <PatientFilter onFilterChange={onFilterChange} />
                    <InputSearch keyword={keyword} handleQuerySearch={handleQuerySearch} />
                    <TableProfilePatient
                        onClickRow={handleShow}
                        searchByName={keyword}
                        limitRow={5}
                    />
                    {
                        status === 'get patient by id loading' ?
                            <LoadingScreen isFullScreen={true} />
                        :
                            <div key={detailPatient.id} ref={ref} style={{ display: show ? 'block' : 'none' }}>
                                <ContentBox 
                                    title="Profil"
                                    dataContent={
                                        <BabyProfileForm 
                                            inputEnabled={false} 
                                            detailPatient={detailPatient}
                                        />
                                    } 
                                />
                                <ContentBox 
                                    title="Layanan yang diberikan"
                                    dataContent={
                                        <BabyVaccinationForm 
                                            showCancelButton={false}
                                            onDataSave={handleSaveMedis}
                                            saveButtonLoading={status === 'update patient vaccination loading' ? true : false}
                                            detailPatient={detailPatient}
                                        />
                                    } 
                                />
                                <ContentBox 
                                    title="Riwayat"
                                    // dataContent={
                                    //     <TableHistoryVaccination 
                                    //         idPatient={detailPatient.id}
                                    //     />
                                    // } 
                                />
                            </div>
                    }
                    <Modal show={showCertificate} onHide={handleCloseCertificate}> 
                        <Modal.Header>
                            <Modal.Title>Surat Keterangan Imunisasi/Vaksin Posyandu</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <VaccineCertificate
                                onCancel={handleCloseCertificate}
                                fileName={detailPatient.baby_nik}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            
                        </Modal.Footer>
                    </Modal>
                </>
            }
        />
    );
}