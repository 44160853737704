import React, { useState, useEffect } from "react";

import "./VaccineCertificate.scss";
import htmltopdf from "../../../_helpers/HtmltoPdf";

export default function VaccineCertificate(props) {
    const savetopdf = () =>
    {
        const fileName = props.fileName ?? 'download';
        const dochtml = document.getElementById('vaccine_certificate');
        htmltopdf(dochtml, fileName);
    }
    const handleCancel = () => {
        props.onCancel()
    }

    return (
        <div className="container-vaccine-certificate">
            <div id="vaccine_certificate">aaaaaaaaaaaaaaaaaa</div>
            <button type="button" className="btn-first" onClick={savetopdf}>Simpan</button>
            <button type="button" className="btn-second" onClick={handleCancel}>Batal</button>
        </div>
    )
}
