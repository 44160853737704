import React, { useState, useEffect } from "react";
import useViewModel from "./ViewModel";
import Modal from 'react-bootstrap/Modal';

import "./PatientRegister.scss";

import Sidebar from "../../../components/Sidebar/Sidebar";
import BabyForm from "../../../components/BabyForm/BabyRegisterForm";
import ContainerContents from "../../../components/ContainerContents/ContainerContents";

import checkIcon from '../../../Assets/Images/Check.svg';

export default function PatientRegister() {
    const { handleSave, handleBack, registerPatientStatus, handleContinue } = useViewModel();

    const textContentSectionTitle = ' font-poppins font-size-18 font-weight-400 text-color-8 ';
    const textRegisterModalSuccessTitle = ' font-poppins font-size-18 font-weight-600 text-color-8 ';

    return (
        <ContainerContents 
            activeMenu="patient"
            contentTitle="Register"
            contents={
                <>
                    <p className={"text-start " + textContentSectionTitle}>Registrasi &gt; Tambah Pasien</p>
                    <BabyForm 
                        onDataSave={handleSave}
                        onCancel={handleBack}
                        saveButtonLoading={registerPatientStatus === 'register patient loading' ? true : false}
                    />
                    <Modal show={registerPatientStatus === 'register patient succeeded' ? true : false} backdrop='static' keyboard={false}> 
                        <Modal.Body className="d-flex flex-column gap-5 align-items-center">
                            <p className={textRegisterModalSuccessTitle}>Data anda Telah Tersimpan</p>
                            <img className='check-icon-100' src={checkIcon} alt="" />
                            <button type="button" className="btn-first" onClick={handleContinue}>Lanjutkan</button>
                        </Modal.Body>
                    </Modal>
                </>
            }
        />
    );
}