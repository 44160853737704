import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth.slice';
import patientReducer from './patient.slice';

export default configureStore({
  reducer: {
    auth: authReducer,
    patient: patientReducer,
  }
});