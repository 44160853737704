import { useState, useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { getToken, login } from '../../../../redux/auth.slice';

export default function AccountLoginViewModel() {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loginStatus = useSelector((state) => state.auth.status);
    const error = useSelector((state) => state.auth.error);


    const [inputs, setInputs] = useState({});

    const handleLogin = (event) => 
    {
        event.preventDefault();
        if (!("nik" in inputs) || inputs.nik.trim() === "") {
            alert("Masukkan NIK")
        } else if (!("password" in inputs) || inputs.password.trim() === "") {
            alert("Masukkan Password")
        } else {
            const nik = inputs.nik ?? '';
            const password = inputs.password ?? '';
            dispatch(login({nik, password}));
        }
    }

    const handleChange = (event) => 
    {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    useEffect(() => {
        if (loginStatus === 'login succeeded') {
            setInputs({});
            window.location.reload(false);
        } else if (loginStatus === 'nik or password wrong') {
            console.log('nik or password wrong')
        } else if (loginStatus === 'The password field is required.') {
            console.log('The password field is required.');
        } else if (loginStatus === 'The nik field is required.') {
            console.log('The nik field is required.')
        }
    }, [loginStatus])

    return {
        token,
        loginStatus,
        handleChange,
        inputs,
        handleLogin,
        error,
    }
}