import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { fetchPatientById, updatePatientVaccination, getPatientById } from '../../../../redux/patient.slice';

export default function CalculationViewModel() {
    const dispatch = useDispatch();
    const detailPatient = useSelector(getPatientById) ?? {};
    const status = useSelector((state) => state.patient.status);
    const error = useSelector((state) => state.patient.error);

    const ref = useRef(null);

    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);

    const handleShow = (id) => {
        dispatch(fetchPatientById({id}));
    }

    const handleQuerySearch = (event) => 
    {
        const query = event.target.value;
        setKeyword(query);
    }

    const handleSaveMedis = (dataMedical) => {
        const id = detailPatient.id
        console.log(dataMedical)
        dispatch(updatePatientVaccination({ id, ...dataMedical }));
    }

    const onDataChange = (data) => {
        // simpan pengukuran ke api detail data
        alert("Data berhasil disimpan");
    }

    const handleCloseCertificate = () => {
        setShowCertificate(false);
    }

    const onFilterChange = (values) => {
        
    }

    useEffect(() => {
        if (status === 'get patient by id succeeded') {
            setShow(true);
        } else if (status === 'get patient by id loading') {
            setShow(false)
        }
    }, [status]);

    useEffect(() => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    }, [show]);

    return {
        onFilterChange,
        keyword,
        handleQuerySearch,
        handleShow,
        show,
        detailPatient,
        handleSaveMedis,
        handleCloseCertificate,
        showCertificate,
        status,
        ref,
    }
}