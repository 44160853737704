import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const token = localStorage.getItem('access_token');

const initialState = {
    data: {},
    status: 'idle',
    error: null
  };

// kendala di rw dan desa pasien, lagi di fix
export const registerPatient = createAsyncThunk('register/patient', async ({ 
    nama_balita,
    nik_balita,
    tanggal_lahir,
    alamat,
    jenis_kelamin,
    golongan_darah,
    berat_badan_lahir_bayi,
    kms,
    asuransi_kesehatan,
    nama_bapak,
    nik_bapak,
    nama_ibu,
    nik_ibu,
    nomor_telepon,
 }) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token ?? '',
        },
        body: JSON.stringify({
            "nama_balita": nama_balita,
            "nik_balita": nik_balita,
            "tanggal_lahir": tanggal_lahir,
            "alamat": alamat,
            "jenis_kelamin" : jenis_kelamin,
            "golongan_darah": golongan_darah,
            "berat_badan_lahir_bayi": berat_badan_lahir_bayi,
            "kms": kms,
            "asuransi_kesehatan": asuransi_kesehatan,
            "nama_bapak": nama_bapak,
            "nik_bapak" : nik_bapak,
            "nama_ibu": nama_ibu,
            "nik_ibu": nik_ibu,
            "nomor_telepon": nomor_telepon,
        })
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/register/pasien`, requestOptions);
    const data = await response.json();
    return data;
});

export const updatePatients = createAsyncThunk('update/patient', async ({ 
        id,
        nama_balita,
        nik_balita,
        tanggal_lahir,
        alamat,
        jenis_kelamin,
        golongan_darah,
        berat_badan_lahir_bayi,
        kms,
        asuransi_kesehatan,
        nama_bapak,
        nik_bapak,
        nama_ibu,
        nik_ibu,
        nomor_telepon,
    }) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token ?? '',
        },
        body: JSON.stringify({
            "nama_balita": nama_balita,
            "nik_balita": nik_balita,
            "tanggal_lahir": tanggal_lahir,
            "alamat": alamat,
            "jenis_kelamin" : jenis_kelamin,
            "golongan_darah": golongan_darah,
            "berat_badan_lahir_bayi": berat_badan_lahir_bayi,
            "kms": kms,
            "asuransi_kesehatan": asuransi_kesehatan,
            "nama_bapak": nama_bapak,
            "nik_bapak" : nik_bapak,
            "nama_ibu": nama_ibu,
            "nik_ibu": nik_ibu,
            "nomor_telepon": nomor_telepon,
        })
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/update/pasien/${id}`, requestOptions);
    const data = await response.json();
    return data;
});

export const fetchAllPatients = createAsyncThunk('getAll/patient', async ({page, limit}) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token ?? '',
        },
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/get/pasien?page=${page}`, requestOptions);
    const data = await response.json();
    return data;
});

export const fetchAllPatientsBySearch = createAsyncThunk('getAllBySearch/patient', async ({page, searchData}) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token ?? '',
        },
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/get/pasien/search?data=${searchData}&page=${page}`, requestOptions);
    const data = await response.json();
    return data;
});

export const fetchPatientById = createAsyncThunk('fetchPatientById/patient', async ({ id }) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token ?? '',
        },
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/get/pasien/${id}`, requestOptions);
    const data = await response.json();
    return data;
});

export const updatePatientMeasurement = createAsyncThunk('updatePatientMeasurement/patient', async ({ 
        id,
        berat_badan,
        tinggi_badan,
        lingkar_kepala,
        lingkar_lengan_atas,
        asi_eksklusif,
        posisi,
    }) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token ?? '',
        },
        body: JSON.stringify({
            "berat_badan": berat_badan,
            "tinggi_badan": tinggi_badan,
            "lingkar_kepala": lingkar_kepala,
            "lingkar_lengan_atas": lingkar_lengan_atas,
            "asi_eksklusif": asi_eksklusif,
            "posisi" : posisi
        })
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/pengukuran/pasien/${id}`, requestOptions);
    const data = await response.json();
    return data;
});

export const updatePatientVaccination = createAsyncThunk('updatePatientVaccination/patient', async ({ 
        id,
        vitamin_a = '0',
        oralit = '0',
        campak = '0',
        dpt = '0',
        polio = '0',
        bcg = '0',
        hb = '0',
    }) => {
        console.log(vitamin_a)
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token ?? '',
        },
        body: JSON.stringify({
            "vitamin_a": vitamin_a,
            "oralit": oralit,
            "campak": campak,
            "dpt": dpt,
            "polio": polio,
            "bcg": bcg,
            "hb": hb,
        })
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/medis/pasien/${id}`, requestOptions);
    const data = await response.json();
    return data;
});

const authSlice = createSlice({
    name: 'patient',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(registerPatient.pending, (state, action) => {
            state.status = 'register patient loading';
        })
        builder.addCase(registerPatient.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.status = 'register patient succeeded';
                state.dataRegister = action.payload.data;
            }
            // } else if (action.payload.meta.code === 500) {
            //     state.status = 'nik or password wrong';
            //     if ('password' in action.payload.data.error) {
            //         state.status = action.payload.data.error.password[0]
            //     }
            //     if ('nik' in action.payload.data.error) {
            //         state.status = action.payload.data.error.nik[0]
            //     }
            // }
        })
        builder.addCase(registerPatient.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        // -----------------------------------------------------------------------------------------
        builder.addCase(updatePatients.pending, (state, action) => {
            state.status = 'update patient loading';
        })
        builder.addCase(updatePatients.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.status = 'update patient succeeded';
            }
            // } else if (action.payload.meta.code === 500) {
            //     state.status = 'nik or password wrong';
            //     if ('password' in action.payload.data.error) {
            //         state.status = action.payload.data.error.password[0]
            //     }
            //     if ('nik' in action.payload.data.error) {
            //         state.status = action.payload.data.error.nik[0]
            //     }
            // }
        })
        builder.addCase(updatePatients.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        // -----------------------------------------------------------------------------------------
        builder.addCase(fetchAllPatients.pending, (state, action) => {
            state.status = 'get all patient loading';
        })
        builder.addCase(fetchAllPatients.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.data = action.payload.data;
                const total = state.data.data.total ?? 0;
                const per_page = state.data.data.per_page ?? 0;
                state.total = Math.ceil(total / per_page);
                state.allPatients = state.data.data.data ?? [];
                state.status = 'get all succeeded';
            }
        })
        builder.addCase(fetchAllPatients.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        // -----------------------------------------------------------------------------------------
        builder.addCase(fetchAllPatientsBySearch.pending, (state, action) => {
            state.status = 'get all patient loading';
        })
        builder.addCase(fetchAllPatientsBySearch.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.data = action.payload.data;
                const total = state.data.data.total ?? 0;
                const per_page = state.data.data.per_page ?? 0;
                state.total = Math.ceil(total / per_page);
                state.allPatients = state.data.data.data ?? [];
                state.status = 'get all succeeded';
            }
        })
        builder.addCase(fetchAllPatientsBySearch.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        // -----------------------------------------------------------------------------------------
        builder.addCase(fetchPatientById.pending, (state, action) => {
            state.status = 'get patient by id loading';
        })
        builder.addCase(fetchPatientById.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.data = action.payload.data;
                state.patientById = state.data.data ?? {};
                state.status = 'get patient by id succeeded';
            }
        })
        builder.addCase(fetchPatientById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        // -----------------------------------------------------------------------------------------
        builder.addCase(updatePatientMeasurement.pending, (state, action) => {
            state.status = 'update patient measurement loading';
        })
        builder.addCase(updatePatientMeasurement.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.patientById = state.data.data ?? {};
                state.status = 'update patient measurement succeeded';
            }
        })
        builder.addCase(updatePatientMeasurement.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        // -----------------------------------------------------------------------------------------
        builder.addCase(updatePatientVaccination.pending, (state, action) => {
            state.status = 'update patient vaccination loading';
        })
        builder.addCase(updatePatientVaccination.fulfilled, (state, action) => {
            state.status = 'failed';
            if (action.payload.meta.code === 200) {
                state.patientById = state.data.data ?? {};
                state.status = 'update patient vaccination succeeded';
            }
        })
        builder.addCase(updatePatientVaccination.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        // -----------------------------------------------------------------------------------------
      },
});

export const getPages = (state) => state.patient.total;
export const getAllPatients = (state) => state.patient.allPatients;
export const getPatientById = (state) => state.patient.patientById;

export default authSlice.reducer;