import React from "react";
import useViewModel from "./ViewModel";
import { useNavigate } from "react-router-dom";
import "./AccountLogin.scss";
import logo from '../../../Assets/Images/Logo.svg';

export default function AccountLogin() {
    const { loginStatus, handleChange, inputs, handleLogin } = useViewModel();

    return (
        <div className="login-page">
            <div className="box">
                <img className='logo200px' src={logo} alt="" />
                <div className="form-login">
                    <form onSubmit={handleLogin}>
                        <input className="input-login form-control input-without-stepper" type="number" name='nik' value={inputs.nik || ""} onChange={handleChange} placeholder='NIK' required/>
                        <div style={{ height: "20px" }}></div>
                        <input className="input-login form-control" type="password" name='password' value={inputs.password || ""} onChange={handleChange} placeholder='Password' required/>
                        <div style={{ height: "40px" }}></div>
                        <button className='btn-first' id='btn-login' type="submit" disabled={loginStatus === 'loading' ? true : false}>
                            {
                                loginStatus === 'loading' ?
                                    <>
                                        <span className="spinner-border spinner-border-sm" role={"status"} aria-hidden="true"></span>
                                        <span className="sr-only"> Loading...</span>
                                    </>
                                :
                                    <span>Masuk</span>
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}