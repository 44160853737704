import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

import "./Table.scss";

export default function TablePatientRecap(props) {
    const [dataPatients, setDataPatients] = useState([]);
    const [detailPatient, setDetailPatient] = useState({});
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(props.limitRow ?? 10);
    const [pages, setPages] = useState(0);

    const listDataPatient = {
        'baby_name' : 'Eren Yeager',
        'date' : '04 Desember 2022',
        'age' : '6',
        'father_name' : 'Grisha Yeager',
        'mother_name' : 'Carla Yeager',
        'gender' : 'Laki-Laki',
        'weight' : 'O',
        'height' : 'O',
    };

    const listDataPatients = [];
    
    for (let i = 0; i < 25; i++) {
        listDataPatients.push({...listDataPatient});
        listDataPatients[i].id = i;
        listDataPatients[i].baby_name += (i+1);
    }

    useEffect(() => {
        getDataPatient(props.searchByName ?? '');
        // dispatch(fetchPatients({page, limit}));
    }, [page, props.searchByName]);

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    const handleClickRow = (id) => {
        setDetailPatient(
            dataPatients.find((value) => value.id === id)
        );
        props.onClickRow(id);
    }

    const getDataPatient = async (querySearch) => {
        setPages(Math.ceil(listDataPatients.length/limit));
        setDataPatients(listDataPatients.slice(page*limit, (page+1)*limit));
    }

    return (
            <>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Nama</th>
                            <th scope="col">Tanggal Lahir</th>
                            <th scope="col">Umur (Bulan)</th>
                            <th scope="col">Nama Bapak</th>
                            <th scope="col">Nama Ibu</th>
                            <th scope="col">Jenis Kelamin</th>
                            <th scope="col">Berat Badan</th>
                            <th scope="col">Tinggi Badan</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataPatients.map((patient) => 
                                <tr className="clickable-table-row" key={patient.id} onClick={() => handleClickRow(patient.id)}>
                                    <td>{patient.baby_name}</td>
                                    <td>{patient.date}</td>
                                    <td>{patient.age}</td>
                                    <td>{patient.father_name}</td>
                                    <td>{patient.mother_name}</td>
                                    <td>{patient.gender}</td>
                                    <td>{patient.weight}</td>
                                    <td>{patient.height}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <nav
                className="pagination"
                role="navigation"
                aria-label="pagination"
            >
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pages}
                    onPageChange={changePage}
                    containerClassName={"pagination-list"}
                    pageLinkClassName={"pagination-link"}
                    previousClassName={"container-previous"}
                    nextClassName={"container-next"}
                    previousLinkClassName={"pagination-previous"}
                    nextLinkClassName={"pagination-next"}
                    activeLinkClassName={"pagination-link is-active"}
                    disabledLinkClassName={"pagination-link is-disabled"}
                    breakClassName={"pagination-break"}
                />
            </nav>
        </>
    )
}
