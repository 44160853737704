import React, { useState, useEffect } from "react";
import "./ContainerContents.scss";
import Responsive from "../../../_helpers/Responsive";
import Sidebar from "../Sidebar/Sidebar";

export default function ContainerContents(props) {
    const contents = props.contents ?? <></>;
    const activeMenu = props.activeMenu ?? '';
    const contentTitle = props.contentTitle ?? '';

    const { isDesktopOrLaptop, isTabletOrMobile } = Responsive();

    return (
        <div className={"sidebar-and-content " + (isTabletOrMobile && 'flex-column')}>
            <Sidebar activeMenu={activeMenu}/>
            <div className={"contents w-100 py-5 pe-5 d-flex flex-column gap-3"}>
                <p className="font-poppins font-size-18 font-weight-600 text-color-8 text-start">{contentTitle}</p>
                {contents}
            </div>
        </div>
    )
}
