import { useMediaQuery } from 'react-responsive'

export default function Responsive() {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    return {
        isDesktopOrLaptop,
        isTabletOrMobile,
        isPortrait,
    }
}