import React, { useState, useEffect } from "react";
import useViewModel from "./ViewModel";

import "./Calculation.scss";
import Sidebar from "../../../components/Sidebar/Sidebar";
import InputSearch from "../../../components/InputSearch/InputSearch";
import BabyProfileForm from "../../../components/BabyForm/BabyProfileForm";
import TableProfilePatient from "../../../components/Table/TableProfilePatient";
import PatientFilter from "../../../components/Filter/PatientFilter";
import ContainerContents from "../../../components/ContainerContents/ContainerContents";
import LoadingScreen from "../../../components/LoadingComponent/LoadingScreen";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ContentSectionBox from "../../../components/ContentSectionBox/ContentSectionBox";

export default function Calculation() {
    const {
        onFilterChange,
        keyword,
        handleQuerySearch,
        handleShow,
        show,
        detailPatient,
        status,
        ref,
    } = useViewModel();

    const dataStatistik = [
        {
            id: 1,
            value : '45',
            title: 'Jumlah Balita yang terdaftar',
        },
        {
            id: 2,
            value : '45',
            title: 'Jumlah Balita yang datang',
        },
        {
            id: 3,
            value : '45',
            title: 'Jumlah Balita yang memiliki KMS',
        },
        {
            id: 4,
            value : '45',
            title: 'Jumlah Balita yang BB nya Naik',
        },
    ]

    return (
        // <div className="sidebar-and-content">
        //     <Sidebar activeMenu="calculation"/>
        //     <div className="contents">
        //         <div className="contents-box contents-box-inline">
        //             <p className="content-title">Perhitungan</p>
        //             <PatientFilter onFilterChange={onFilterChange} />
        //         </div>
        //         <div className="contents-box">
        //             <div className="content">
        //                 <InputSearch keyword={keyword} handleQuerySearch={handleQuerySearch} />
        //             </div>
        //         </div>
        //         <div className="contents-box">
        //             <TableProfilePatient
        //                 onClickRow={handleShow}
        //                 searchByName={keyword}
        //                 limitRow={5}
        //             />
        //         </div>
        //         {
        //             status === 'get patient by id loading' ?
        //                 <div className="loading-full-screen">
        //                     <div className="text-center">
        //                         <div className="spinner-border big-spinner" role="status"></div>
        //                         <div className="loading-full-screen-text">Loading</div>
        //                     </div>
        //                 </div>
        //             :
        //                 <div key={detailPatient.id} ref={ref} style={{ display: show ? 'block' : 'none' }}>
        //                     <div className="contents-box">
        //                         <p className="content-title">Profil</p>
        //                         <div className="content content-profile">
        //                             <BabyProfileForm 
        //                                 inputEnabled={false} 
        //                                 detailPatient={detailPatient}
        //                             />
        //                         </div>
        //                     </div>
        //                     <div className="contents-box">
        //                         <p className="content-title">Statistik</p>
        //                         <div className="content content-wrap">
        //                             <div className="sub-content">
        //                                 <p className="sub-content-title mb-10">Berat Badan</p>
        //                                 <div className="content-section">
        //                                     <p className="content-section-value">{detailPatient.berat_badan}</p>
        //                                     <p className="content-section-title">{detailPatient.BB_U}</p>
        //                                 </div>
        //                             </div>
        //                             <div className="sub-content">
        //                                 <p className="sub-content-title mb-10">Tinggi Badan</p>
        //                                 <div className="content-section">
        //                                     <p className="content-section-value">{detailPatient.tinggi_badan}</p>
        //                                     <p className="content-section-title">{detailPatient.TB_U}</p>
        //                                 </div>
        //                             </div>
        //                             <div className="sub-content">
        //                                 <p className="sub-content-title mb-10">Berat Badan menurut Tinggi Badan</p>
        //                                 <div className="content-section">
        //                                     <p className="content-section-value">{detailPatient.BB_TB}</p>
        //                                     <p className="content-section-title">{detailPatient.BB_TB}</p>
        //                                 </div>
        //                             </div>
        //                             <div className="sub-content">
        //                                 <p className="sub-content-title mb-10">Indeks Massa Tubuh Menurut Umur</p>
        //                                 <div className="content-section">
        //                                     <p className="content-section-value">{detailPatient.IMT_U_T}</p>
        //                                     <p className="content-section-title">{detailPatient.IMT_U_T}</p>
        //                                 </div>
        //                             </div>
        //                             {/* <div className="sub-content">
        //                                 <p className="sub-content-title mb-10">Indeks Massa Tubuh Menurut Umur</p>
        //                                 <div className="content-section">
        //                                     <p className="content-section-value">{detailPatient.IMT_U_B}</p>
        //                                     <p className="content-section-title">{detailPatient.IMT_U_B}</p>
        //                                 </div>
        //                             </div> */}
        //                         </div>
        //                     </div>
        //                 </div>
        //         }
        //     </div>
        // </div>
        <ContainerContents 
            activeMenu="calculation"
            contentTitle="Perhitungan"
            contents={
                <>
                    <PatientFilter onFilterChange={onFilterChange} />
                    <InputSearch keyword={keyword} handleQuerySearch={handleQuerySearch} />
                    <TableProfilePatient
                        onClickRow={handleShow}
                        searchByName={keyword}
                        limitRow={5}
                    />
                    {
                        status === 'get patient by id loading' ?
                            <LoadingScreen isFullScreen={true} />
                        :
                            <div key={detailPatient.id} ref={ref} style={{ display: show ? 'block' : 'none' }}>
                                <ContentBox 
                                    title="Profil"
                                    dataContent={
                                        <BabyProfileForm 
                                            inputEnabled={false} 
                                            detailPatient={detailPatient}
                                        />
                                    } 
                                />
                                <ContentBox 
                                    title="Statistik"
                                    dataContent={
                                        [
                                            dataStatistik.map(data =>
                                                <ContentSectionBox
                                                    value={data.value}
                                                    title={data.title}
                                                />    
                                            )
                                        ]
                                    } 
                                />
                            </div>
                    }
                </>
            }
        />
    );
}