import React, { useState, useEffect } from "react";
import "./BabyForm.scss";
import calculateAge, { conversionDateToDefaultFormat, conversionMonthToNumber, conversionMonthToString } from "../../../_helpers/CalculateAge"

import DatePicker from 'react-date-picker'

export default function BabyForm(props) {
    const formData = props.formData ?? [];
    const buttonData = props.buttonData ?? [];

    const textLabelInput = ' font-poppins font-size-12 font-weight-400 text-color-8 ';

    return (
        <div className="container-form-body d-flex flex-column gap-4 w-100">
            {
                formData.map(data => 
                    <div className="content-form-body d-flex flex-column gap-3" key={data.id}>
                        <label className={"text-start " + textLabelInput}>{data.title}</label>
                        {data.inputTag}
                    </div>
                )
            }
            {buttonData}
        </div>
    )
}