export default function calculateAge(birthdate) {
    const difference = +new Date() - new Date(birthdate);
    let age = {};

    if (difference > 0) {
        age = {
            'Year(s)': Math.floor(difference / (1000 * 60 * 60 * 24 * 30 * 12)),
            'Month(s)': Math.floor(difference / (1000 * 60 * 60 * 24 * 30) % 12),
            'Day(s)': Math.floor(difference / (1000 * 60 * 60 * 24) % 30),
            'AllDay(s)': Math.floor(difference / (1000 * 60 * 60 * 24)),
            'AllMonth(s)': Math.floor(difference / (1000 * 60 * 60 * 24 * 30)),
      };
    }

    return age;
};

export const conversionDateToDefaultFormat = (date) => {
    if (date === undefined) {
        return new Date();
    } else {
        console.log(date)
        const dateSplit = date.toString().split('-');
        const newDate = dateSplit[2] + " " + dateSplit[1] + " " + dateSplit[0]
        console.log(Date(newDate))
        return new Date(newDate);
    }
}

export const conversionMonthToNumber = (month) => {
    const monthLower = month.toLowerCase();
    if (monthLower === 'januari') {
        return '1'
    } else if (monthLower === 'februari') {
        return '2'
    } else if (monthLower === 'maret') {
        return '3'
    } else if (monthLower === 'april') {
        return '4'
    } else if (monthLower === 'mei') {
        return '5'
    } else if (monthLower === 'juni') {
        return '6'
    } else if (monthLower === 'juli') {
        return '7'
    } else if (monthLower === 'agustus') {
        return '8'
    } else if (monthLower === 'september') {
        return '9'
    } else if (monthLower === 'oktober') {
        return '10'
    } else if (monthLower === 'november') {
        return '11'
    } else if (monthLower === 'desember') {
        return '12'
    } else {
        return '1'
    }
}

export const conversionMonthToString = (month) => {
    const monthNumber = parseInt(month) ?? 0;
    const monthString = ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];
    return monthString[monthNumber];
}