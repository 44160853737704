import React, { useState, useEffect } from "react";
import useViewModel from "./ViewModel";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./Measurement.scss";
import Sidebar from "../../../components/Sidebar/Sidebar";
import InputSearch from "../../../components/InputSearch/InputSearch";
import BabyProfileForm from "../../../components/BabyForm/BabyProfileForm";
import BabyMeasurementForm from "../../../components/BabyForm/BabyMeasurementForm";
import TableProfilePatient from "../../../components/Table/TableProfilePatient";
import PatientFilter from "../../../components/Filter/PatientFilter";
import ContainerContents from "../../../components/ContainerContents/ContainerContents";
import LoadingScreen from "../../../components/LoadingComponent/LoadingScreen";
import ContentBox from "../../../components/ContentBox/ContentBox";

export default function Measurement() {
    const {
        onFilterChange,
        keyword,
        handleQuerySearch,
        handleShow,
        show,
        detailPatient,
        handleSaveMeasurement,
        status,
        ref
    } = useViewModel();

    return (
        <ContainerContents 
            activeMenu="measurement"
            contentTitle="Pengukuran"
            contents={
                <>
                    <PatientFilter onFilterChange={onFilterChange} />
                    <InputSearch keyword={keyword} handleQuerySearch={handleQuerySearch} />
                    <TableProfilePatient
                        onClickRow={handleShow}
                        searchByName={keyword}
                        limitRow={5}
                    />
                    {
                        status === 'get patient by id loading' ?
                            <LoadingScreen isFullScreen={true} />
                        :
                            <div key={detailPatient.id} ref={ref} style={{ display: show ? 'block' : 'none' }}>
                                <ContentBox 
                                    title="Profil"
                                    dataContent={
                                        <BabyProfileForm 
                                            inputEnabled={false} 
                                            detailPatient={detailPatient}
                                        />
                                    } 
                                />
                                <ContentBox 
                                    title="Pengukuran"
                                    dataContent={
                                        <BabyMeasurementForm 
                                            detailPatient={detailPatient}
                                            onDataSave={handleSaveMeasurement}
                                            saveButtonLoading={status === 'update patient measurement loading' ? true : false}
                                        />
                                    } 
                                />
                            </div>
                    }
                </>
            }
        />
    );
}