import React, { useState, useEffect } from "react";
import "./ContentBox.scss";
import Responsive from "../../../_helpers/Responsive";

import ContentSectionBox from "../ContentSectionBox/ContentSectionBox";

export default function ContentBox(props) {
    const title = props.title ?? '';
    const dataContent = props.dataContent ?? [];

    const { isDesktopOrLaptop, isTabletOrMobile } = Responsive();

    const textSubContentTitle = ' font-poppins font-size-14 font-weight-600 text-color-8 '

    return (
        <div className="contents-box" key={dataContent.id}>
            {
                title != null ? <p className={"mb-4 text-start " + textSubContentTitle}>{title}</p> : <></>
            }
            <div className={"content d-flex flex-wrap w-100 " + (isDesktopOrLaptop ? 'gap-4' : 'gap-2 justify-content-around')}>
                {dataContent}
            </div>
        </div>
    )
}
