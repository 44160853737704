import React, { useState, useEffect } from "react";
import "./BabyForm.scss";
import calculateAge from "../../../_helpers/CalculateAge";
import isSpecialMonths from "../../../_helpers/SpecialMonths";

import BabyForm from "./BabyForm";

export default function BabyMeasurementForm(props) {
    const saveButtonLoading = props.saveButtonLoading ?? false;
    const inputEnabled = props.inputEnabled ?? true;
    const [patientMeasurement, setPatientMeasurement] = useState(props.detailPatient ?? {});
    const [age, setAge] = useState(calculateAge(patientMeasurement.date));

    const handleChange = (event) => 
    {
        const name = event.target.name;
        const value = event.target.value;
        if (name === 'lingkar_kepala' || name === 'lingkar_lengan_atas') {
            if (isSpecialMonths()) {
                setPatientMeasurement(values => ({...values, [name]: value}));
            }
        } else {
            setPatientMeasurement(values => ({...values, [name]: value}));
        }
    }

    const handleSave = () => {
        if (!("berat_badan" in patientMeasurement) || patientMeasurement.berat_badan.trim() === "") {
            alert("Masukkan Berat Badan")
        } else if (!("tinggi_badan" in patientMeasurement) || patientMeasurement.tinggi_badan.trim() === "") {
            alert("Masukkan Tinggi Badan")
        } else if ((!("lingkar_kepala" in patientMeasurement) || patientMeasurement.lingkar_kepala.trim() === "") && isSpecialMonths()) {
            alert("Masukkan Lingkar Kepala")
        } else if ((!("lingkar_lengan_atas" in patientMeasurement) || patientMeasurement.lingkar_lengan_atas.trim() === "") && isSpecialMonths()) {
            alert("Masukkan Lingkar Lengan Atas")
        } else {
            if (age['AllMonth(s)'] <= 6) {
                if (!("asi_eksklusif" in patientMeasurement) || patientMeasurement.asi_eksklusif.trim() === "") {
                    alert("Apakah diberikan ASI ekslusif?")
                }
            } else {
                props.onDataSave(patientMeasurement);
            }
            
        }
    };

    const dataBabyMeasurementForm = [
        {
            id: 1,
            title: 'Berat Badan',
            inputTag:   <div className="input-group">
                            <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='berat_badan' value={patientMeasurement.berat_badan || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='0' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} /> 
                            <span className={'input-group-text ' + (inputEnabled ? ' input-box-enabled ' : ' input-box-disabled ')}>gram</span>
                        </div>,
        },
        {
            id: 2,
            title: 'Tinggi Badan',
            inputTag:   <div className="input-group">
                            <input className={"form-control " + (inputEnabled ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='tinggi_badan' value={patientMeasurement.tinggi_badan || ""} onChange={inputEnabled ? handleChange : ()=>{}} placeholder='0' required={inputEnabled ? true : false} readOnly={inputEnabled ? false : true} /> 
                            <span className={'input-group-text ' + (inputEnabled ? ' input-box-enabled ' : ' input-box-disabled ')}>gram</span>
                        </div>,
        },
        {
            id: 3,
            title: 'Lingkar Kepala',
            inputTag:   <div className="input-group">
                            <input className={"form-control " + (inputEnabled && isSpecialMonths() ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='lingkar_kepala' value={patientMeasurement.lingkar_kepala || ""} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} placeholder='0' required={inputEnabled && isSpecialMonths() ? true : false} readOnly={inputEnabled && isSpecialMonths() ? false : true} /> 
                            <span className={'input-group-text ' + (inputEnabled && isSpecialMonths() ? ' input-box-enabled ' : ' input-box-disabled ')}>cm</span>
                        </div>,
        },
        {
            id: 4,
            title: 'Lingkar Lengan Atas',
            inputTag:   <div className="input-group">
                            <input className={"form-control " + (inputEnabled && isSpecialMonths() ? 'input-box-enabled' : 'input-box-disabled')} type="number" name='lingkar_lengan_atas' value={patientMeasurement.lingkar_lengan_atas || ""} onChange={inputEnabled && isSpecialMonths() ? handleChange : ()=>{}} placeholder='0' required={inputEnabled && isSpecialMonths() ? true : false} readOnly={inputEnabled && isSpecialMonths() ? false : true} /> 
                            <span className={'input-group-text ' + (inputEnabled && isSpecialMonths() ? ' input-box-enabled ' : ' input-box-disabled ')}>cm</span>
                        </div>,
        },
        {
            id: 5,
            title: 'Jenis Kelamin',
            inputTag:   <select id="asi_eksklusif" className={"form-control " + (inputEnabled && (age['AllMonth(s)'] <= 6) ? 'input-box-enabled' : 'input-box-disabled')} name='asi_eksklusif' value={patientMeasurement.asi_eksklusif || ""} onChange={inputEnabled && (age['AllMonth(s)'] <= 6) ? handleChange : ()=>{}} required={inputEnabled && (age['AllMonth(s)'] <= 6) ? true : false} disabled={inputEnabled && (age['AllMonth(s)'] <= 6) ? false : true}>
                            <option disabled value="">Pilih Opsi</option>
                            <option value="1">Iya</option>
                            <option value="0">Tidak</option>
                        </select>,
        },
    ]

    return (
        <BabyForm
            formData={dataBabyMeasurementForm}
        />
    )
}
