import React, { useEffect, useState } from "react";
import "./Sidebar.scss";

import logo from '../../Assets/Images/Logo.svg';
import rekapIcon from '../../Assets/Images/Rekap.svg';
import logoutIcon from '../../Assets/Images/Logout.svg';
import ThreeDotsVertical from '../../Assets/Images/ThreeDotsVertical.svg'

import Responsive from "../../../_helpers/Responsive";

import { PengukuranSvg, PerhitunganSvg, MedisSvg, RegisterSvg, DashboardSvg, RecapSvg } from "../CustomSvg/CustomSvg";

import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../redux/auth.slice';

export default function Sidebar(props) {
    const { isDesktopOrLaptop, isTabletOrMobile } = Responsive();
    const [isHideMenu, setIsHideMenu] = useState(false);

    const activeMenu = props.activeMenu ?? "";

    const dispatch = useDispatch();
    const logoutStatus = useSelector((state) => state.auth.status);
    const error = useSelector((state) => state.auth.error);

    const handleLogout = () => {
        dispatch(logout());
    }

    useEffect(() => {
        if (logoutStatus === 'logout succeeded') {
            window.location.reload(false);
        }
    }, [logoutStatus])

    const handleHideMenu = () => {
        setIsHideMenu(value => !value)
    }

    const menuList = [
        {
            id: 1,
            title: null,
            menu: [
                {
                    id: 1,
                    name: 'Dashboard',
                    icon: DashboardSvg,
                    url: 'dashboard',
                },
            ]
        },
        {
            id: 2,
            title: 'Meja Pasien',
            menu: [
                {
                    id: 1,
                    name: 'Registrasi',
                    icon: RegisterSvg,
                    url: 'patient',
                },
                {
                    id: 2,
                    name: 'Pengukuran',
                    icon: PengukuranSvg,
                    url: 'measurement',
                },
                {
                    id: 3,
                    name: 'Perhitungan',
                    icon: PerhitunganSvg,
                    url: 'calculation',
                },
                {
                    id: 4,
                    name: 'Medis',
                    icon: MedisSvg,
                    url: 'medical',
                },
            ]
        },
        {
            id: 3,
            title: 'Laporan',
            menu: [
                {
                    id: 1,
                    name: 'Rekap',
                    icon: RecapSvg,
                    url: 'recap',
                },
            ]
        }
    ]

    const textSidebarMenuContentNonActive = ' font-poppins font-size-14 font-weight-400 text-color-9 ';
    const textSidebarMenuContentActive = ' font-poppins font-size-14 font-weight-600 text-color-9 ';

    return (
        <>
            <div className={"sidebar " + (isDesktopOrLaptop ? 'position-fixed overflow-auto h-100' : 'sticky-top w-auto')}>
                <div className={"sidebar-content d-flex flex-column mx-5 my-4 gap-4 " + (isTabletOrMobile && 'mt-0 mb-0 ms-3 me-3')}>
                    <div className={"d-flex w-100 " + (isDesktopOrLaptop ? 'justify-content-center' : 'justify-content-between')}>
                        <a href="/dashboard">
                            <img className={isDesktopOrLaptop ? 'logo100px' : 'logo60px'} src={logo} alt="" />
                        </a>
                        {isTabletOrMobile && <img src={ThreeDotsVertical} alt="" onClick={handleHideMenu} />}
                    </div>
                    {isDesktopOrLaptop && <hr className="" style={{ border: '1px solid #D7E7E2', width: "100%" }}></hr>}
                    {(isDesktopOrLaptop || isHideMenu) &&
                        <>
                            {
                                menuList.map(menu => 
                                    <div key={menu.id} className="d-flex flex-column gap-3">
                                        {
                                            menu.title != null ?
                                                    <p className="sidebar-title ">{menu.title}</p>
                                                :
                                                    <></>
                                        }
                                        {
                                            menu.menu.map(subMenu => 
                                                <a key={subMenu.id} href={"/"+subMenu.url} className={"pe-auto text-decoration-none d-flex ps-2 " + (activeMenu === subMenu.url ? textSidebarMenuContentActive : textSidebarMenuContentNonActive)}>
                                                    <subMenu.icon color={activeMenu === subMenu.url ? '#00AE84' : "#70897F"}/>
                                                    <p className='sidebar-menu-text ps-2'>{subMenu.name}</p>
                                                </a>
                                            )
                                        }
                                    </div>
                                )
                            }
                            <div role="button" className={"d-flex ps-2 " + textSidebarMenuContentNonActive} onClick={handleLogout}>
                                <img className='sidebar-menu-icon' src={logoutIcon} alt="" />
                                <p className='sidebar-menu-text m-0 ps-2'>Logout</p>
                            </div>
                        </>
                    }
                </div>
            </div>
            {
                logoutStatus === 'logout loading' ?
                    <div className="loading-full-screen">
                        <div className="text-center">
                            <div className="spinner-border big-spinner" role="status"></div>
                            <div className="loading-full-screen-text">Logout</div>
                        </div>
                    </div>
                :
                    <></>
            }
        </>
    );
}